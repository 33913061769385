import {Navigate, Outlet} from 'react-router-dom';
import {useContext} from 'react'
import {UserContext} from '../context/UserContext';

export const PrivateRoute = ({ children, component: Component, redirectTo, path, ...props }) => {
    
    const {user} = useContext(UserContext);
    return (
        <>
            {user ? 
                children 
            :
                <>
                    {props.pat=="home" ?
                        <Navigate to="/" />
                    :
                        <Outlet/>
                    }
                </>
            }
        </>
    )
};