import { useContext, useState, useEffect, Suspense, useRef } from 'react'
import { UserContext } from '../../../context/UserContext'
import { BsFillTrashFill, BsFillQuestionCircleFill, BsFillCheckCircleFill, BsFillXCircleFill, BsFillPinMapFill, BsFillClockFill, BsFillClipboardCheckFill, BsFillPeopleFill, BsFillBinocularsFill, BsFillCloudUploadFill } from "react-icons/bs"
import { BiLoaderAlt } from "react-icons/bi"
import { Formik } from "formik"
import Spinner from "../../modal/Spinner";
import Nousuario from "../../modal/NoUsuario";
import { SeparateDateSelect } from "../../funciones/WorkDate"
import { Axios } from '../../../context/UserContext';

const CoberturaPuestoSeguridad = (props) => {
   const formikRef = useRef();
   const { user, asignacion, loggedInCheck } = useContext(UserContext)
   const [LocacionHttp, setLocacionHttp] = useState(window.location.href.split("/"));
   const [ShowSpinner, setShowSpinner] = useState(false)
   const [ArrayCliente, setArrayCliente] = useState(asignacion.clientes)
   const [ArrayPersonal, setArrayPersonal] = useState({})
   const [ArrayCobertura, setArrayCobertura] = useState({})
   const [ArrayCedulaCobertura, setArrayCedulaCobertura] = useState({})
   const [ArrayEstructura, setArrayEstructura] = useState({})
   const [ArraySucursales, setArraySucursales] = useState({})
   const [CargaPuesto, setCargaPuesto] = useState({})
   const [TipoStatus, setTipoStatus] = useState({})
   const [OpenFuncion, setOpenFuncion] = useState("")
   const OpenDivFuncion = (value) => { setOpenFuncion(OpenFuncion == value ? "" : value) }
   const [Aviso, setAviso] = useState({ Show: false })
   const [TableCoincidencia, setTableCoincidencia] = useState({
      Show: false,
      Valor: []
   })
   const [progress, setProgress] = useState(0);

   const CerrarAviso = (login) => {
      setAviso({ Show: false })
      setTipoStatus({ Show: false, valor: -1 });
      formikRef.current.setFieldValue('status_puesto', '');
      formikRef.current.setFieldValue('colaborador', '');
      formikRef.current.setFieldValue('observacion', '');
      setCargaPuesto({ Show: false })
   }
   const BuscarCoincidencia = (e) => {
      setTableCoincidencia({ Show: false })
      setCargaPuesto({ Show: false })
      const fecha = document.getElementById('fecha').value;
      const fechasel = SeparateDateSelect(fecha)
      const id_cliente = document.getElementById('cliente').value;
      const tipo_peticion = 1

      if (fecha != "" && id_cliente != "") {
         setShowSpinner(true)
         let array_cobertura = []
         let arraycedula_cobertura = []
         async function main() {
            try {
               const { data } = await Axios.post('/coberturaseguridad.php', {
                  id_cliente, fechasel, tipo_peticion
               });
               /* console.log("data-cobertura", data) */
               if (data.success == 1) {
                  array_cobertura = data.datos
                  array_cobertura.forEach(element => {
                     const idPersonal = element.id_personal_coberturaseguridad;
                     if (!arraycedula_cobertura.some(personal => personal.id_personal === idPersonal)) {
                        arraycedula_cobertura.push(idPersonal);
                     }
                  });
                  setArrayCedulaCobertura(arraycedula_cobertura)
                  setArrayCobertura(array_cobertura)
                  setTableCoincidencia({ Show: true })
               }
               setShowSpinner(false)
            } catch (err) { console.log("err"); console.log(err) }
         }
         main();
      }
   }
   const ChangeFecha = (e) => {
      setTableCoincidencia({ Show: false })
   }
   const ChangeCliente = async (e) => {
      setTableCoincidencia({ Show: false })
      setArraySucursales({})
      setArrayEstructura({})
      setArrayCobertura({})
      const fecha = document.getElementById('fecha').value;
      const [yyyy, mm, dd] = fecha.split("-")
      const specificDate = new Date(yyyy, mm - 1, dd);
      const id_cliente = document.getElementById('cliente').value;
      const HE = document.getElementById('entrada').value;
      const HS = document.getElementById('salida').value;
      const Hentrada = parseInt(HE.replace(":", ""))
      const Hsalida = parseInt(HS.replace(":", ""))
      if (fecha != '' && id_cliente != '') {
         setShowSpinner(true)
         let array_estructura = []
         let array_estructura_carga = []
         let array_sucursales = []
         async function task1() {
            try {
               const { data } = await Axios.post('/personal_consultaseguridad.php', {
                  id_cliente
               });
               /* console.log("data-personal", data) */
               if (data.success == 1) {
                  data.datos.sort((a, b) => {
                     let comparison = a.nombres_personal.localeCompare(b.nombres_personal);
                     if (comparison == 0) {
                        return a.apellidos_personal.localeCompare(b.apellidos_personal);
                     }
                     return comparison;
                  });
                  setArrayPersonal(data.datos)
               }

            } catch (err) { console.log("err"); console.log(err) }
         }
         async function task2() {
            try {
               const { data } = await Axios.post('/estructuraseguridad.php', {
                  id_cliente
               });
               /* console.log("data-estructura", data) */
               if (data.success == 1) {
                  array_estructura = data.datos

                  array_estructura.forEach(element => {
                     const entrada = parseInt(element.he_estructuraseguridad.replace(":", ""))
                     const salida = parseInt(element.hs_estructuraseguridad.replace(":", ""))
                     const [yy, m, d] = element.fechainicio_estructuraseguridad.split("-")
                     const specificDate2 = new Date(yy, m - 1, d);
                     if (specificDate >= specificDate2) {
                        if (entrada >= Hentrada && salida <= Hsalida) {
                           if (entrada < salida && Hentrada < Hsalida) {
                              const idSucursal = element.cf_id_sucursal;
                              if (!array_sucursales.some(sucursal => sucursal.id_sucursal === idSucursal)) {
                                 array_sucursales.push({
                                    id_sucursal: idSucursal,
                                    nombre_sucursal: element.nombre_sucursal,
                                 });
                              }
                              array_estructura_carga.push(element)
                           } else {
                              if (Hentrada > Hsalida) {
                                 const idSucursal = element.cf_id_sucursal;
                                 if (!array_sucursales.some(sucursal => sucursal.id_sucursal === idSucursal)) {
                                    array_sucursales.push({
                                       id_sucursal: idSucursal,
                                       nombre_sucursal: element.nombre_sucursal,
                                    });
                                 }
                                 array_estructura_carga.push(element)
                              }
                           }
                        }
                     }
                  });
                  setArraySucursales(array_sucursales)
                  setArrayEstructura(array_estructura_carga)
               }
            } catch (err) { console.log("err"); console.log(err) }
         }
         async function main() {
            await Promise.all([task1(), task2()]);
            setShowSpinner(false)
            BuscarCoincidencia()
         }
         main()
      }
   }

   const TablePuestos = (props) => {
      const dias = ["dom", "lun", "mar", "mie", "jue", "vie", "sab"];
      const fecha = document.getElementById('fecha').value;
      const [yyyy, mm, dd] = fecha.split("-")
      const specificDate = new Date(yyyy, mm - 1, dd);
      const diaIndex = specificDate.getDay();
      const nombreDia = dias[diaIndex];


      let PAC = 0
      let PC = 0
      let PNC = 0
      let SC = 0
      let NA = 0
      return (
         <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-sky-900">
               <tr>
                  <th width="28%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white"><span className="flex justify-center text-2xl"><BsFillPinMapFill /></span></th>
                  <th width="10%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white"><span className="flex justify-center text-2xl"><BsFillClockFill /></span></th>
                  <th width="5%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white"><span className="flex justify-center text-2xl"><BsFillClipboardCheckFill /></span></th>
                  <th scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white"><span className="flex justify-center text-2xl"><BsFillPeopleFill /></span></th>
                  <th width="5%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white"><span className="flex justify-center text-2xl"><BsFillBinocularsFill /></span></th>
                  <th width="5%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white"></th>
               </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
               {
                  ArrayEstructura.map((ddd, iii) => {
                     if ((props.id_sucursal == ddd.cf_id_sucursal) && (ddd[nombreDia] == 1)) {
                        PAC++
                        let col3 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><button className="text-2xl text-yellow-500" onClick={() => CargarCobertura(props.id_sucursal, props.nombre_sucursal, ddd.id_estructuraseguridad, ddd.nombre_estructuraseguridad, ddd.he_estructuraseguridad, ddd.hs_estructuraseguridad)}><BsFillCloudUploadFill /></button></td>
                        let col4 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"></td>
                        let col5 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"></td>
                        let col6 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"></td>
                        return (
                           <>
                              <tr key={`fila_${iii}`} className="hover:bg-gray-300">
                                 <td className="px-2 py-0.5 text-xs border border-slate-300 text-left">{ddd.nombre_estructuraseguridad}</td>
                                 <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><div className="flex flex-col"><span>E: {ddd.he_estructuraseguridad}</span><span>S: {ddd.hs_estructuraseguridad}</span></div></td>
                                 {ArrayCobertura.map((dddd, iiii) => {
                                    if ((dddd.id_sucursal_coberturaseguridad == ddd.cf_id_sucursal) && (dddd.id_puesto_coberturaseguridad == ddd.id_estructuraseguridad)) {
                                       switch (dddd.id_statuspuesto_coberturaseguridad) {
                                          case 1:
                                             PC++
                                             col3 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center font-bold text-white bg-green-900">{dddd.nombre_statuspuesto}</td>
                                             col4 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-left">{dddd.nombres_personal} {dddd.apellidos_personal} C.I.:{dddd.nrocedula_personal}</td>
                                             break;
                                          case 2:
                                             PNC++
                                             col3 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center font-bold text-white bg-red-900">{dddd.nombre_statuspuesto}</td>
                                             col4 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-left">{dddd.descripcion_statuspuesto}</td>
                                             break;
                                          case 3:
                                             SC++
                                             col3 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center font-bold text-white bg-blue-900">{dddd.nombre_statuspuesto}</td>
                                             col4 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-left">{dddd.descripcion_statuspuesto}</td>
                                             break;
                                          case 4:
                                             NA++
                                             col3 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-center font-bold text-white bg-orange-600">{dddd.nombre_statuspuesto}</td>
                                             col4 = <td className="px-2 py-0.5 text-xs border border-slate-300 text-left">{dddd.descripcion_statuspuesto}</td>
                                             break;
                                       }
                                       if (dddd.obs_coberturaseguridad != "") {
                                          col5 = <td className="px-2 py-0.5 border border-slate-300 text-center" title={dddd.obs_coberturaseguridad}><span className="flex justify-center text-lg"><BsFillBinocularsFill /></span></td>
                                       }
                                       if (ddd.status_puesto != "") {
                                          col6 = <td className="px-2 py-0.5 border border-slate-300 text-center"><div className="flex justify-center align-center"><button className="text-2xl text-red-900" onClick={()=>PreguntaEliminarCargarCobertura(dddd.id_coberturaseguridad, ddd.nombre_sucursal, ddd.nombre_estructuraseguridad, ddd.he_estructuraseguridad, ddd.hs_estructuraseguridad)}><BsFillTrashFill /></button></div></td>
                                       }
                                    }
                                 })}
                                 {col3}
                                 {col4}
                                 {col5}
                                 {col6}
                              </tr>
                           </>
                        )
                     }
                  })

               }
            </tbody>
            {MostrarCantidad(props.numero, PAC, PC, PNC, SC, NA)}
         </table>
      )
   }

   const MostrarCantidad = (numero, PAC, PC, PNC, SC, NA) => {
      let suma = PC + PNC + SC + NA
      setTimeout(() => {
         if (document.getElementById('pac_' + numero + '') !== null) { document.getElementById('pac_' + numero + '').innerHTML = "" + suma + "/" + PAC + "" }
         if (document.getElementById('pc_' + numero + '') !== null) { document.getElementById('pc_' + numero + '').innerHTML = PC }
         if (document.getElementById('pnc_' + numero + '') !== null) { document.getElementById('pnc_' + numero + '').innerHTML = PNC }
         if (document.getElementById('sc_' + numero + '') !== null) { document.getElementById('sc_' + numero + '').innerHTML = SC }
         if (document.getElementById('na_' + numero + '') !== null) { document.getElementById('na_' + numero + '').innerHTML = NA }
      }, 1000);
   }

   const CargarCobertura = (id_sucursal, nombre_sucursal, id_estructura, nombre_estructura, he, hs) => {

      setCargaPuesto({
         Show: true,
         id_sucursal: id_sucursal,
         nombre_sucursal: nombre_sucursal,
         id_puesto: id_estructura,
         nombre_puesto: nombre_estructura,
         entrada: he,
         salida: hs,
      })
   }

   const ChangeStatus = (e) => {
      const cobertura = e.target.value;
      const coberturaOptions = {
         1: { Show: true, valor: 1 },
         2: { Show: true, valor: 2 },
         3: { Show: true, valor: 3 },
         4: { Show: true, valor: 4 }
      };

      const tipoStatusData = coberturaOptions[cobertura] || { Show: false, valor: -1 };
      setTipoStatus(tipoStatusData);
   }

   const CargarPuesto = async () => {

      const fecha = document.getElementById('fecha').value;
      const fechasel = SeparateDateSelect(fecha)
      const id_cliente = document.getElementById('cliente').value;
      const id_status_puesto = document.getElementById('status_puesto').value;
      const id_colaborador = document.getElementById('colaborador').value;
      const obs = document.getElementById('observacion').value.toUpperCase();
      const id_usuario = user.id_usuario
      const id_puesto = CargaPuesto.id_puesto
      const id_sucursal = CargaPuesto.id_sucursal
      const entrada = CargaPuesto.entrada
      const salida = CargaPuesto.salida
      const tipo_peticion = 2;

      const config = {
         onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
         }
      };
      try {
         const { data } = await Axios.post('/coberturaseguridad.php', {
            id_usuario, fechasel, id_cliente, id_puesto, id_status_puesto, id_colaborador, id_sucursal, obs, tipo_peticion, entrada, salida
         }, config);
         console.log("data-insertar", data);
         if (data.success == 1) {
            formikRef.current.setFieldValue('status_puesto', '');
            formikRef.current.setFieldValue('colaborador', '');
            formikRef.current.setFieldValue('observacion', '');
            setCargaPuesto({ Show: false })
            BuscarCoincidencia()
         }
         if (data.success == 0) {
            /* formikRef.current.setFieldValue('status_puesto', '');
            formikRef.current.setFieldValue('colaborador', '');
            formikRef.current.setFieldValue('observacion', ''); */
         }
         setProgress(0)
      } catch (err) {
         console.error("Error during submission:", err);
         setProgress(0)
      } finally {
         setProgress(0)
      }
   }

   const PreguntaEliminarCargarCobertura = (id_cobertura, nombre_sucursal, nombre_puesto, entrada, salida)=>{
      setAviso({
          Show:true,
          Logo:'BsFillQuestionCircleFill',
          Color:'orange-400',
          Texto:"¿ Desea eliminar \""+nombre_puesto+"\"\n\de la sucursal \""+nombre_sucursal+"\" ?",
          AlignTexto:true,
          Vid:id_cobertura,
          Botones:{
              Bcerrar:true,
              Beliminar:true
          }
      })
   }
   const EliminarCargarCobertura = async(id_cobertura)=>{
      const fecha = document.getElementById('fecha').value;
      const fechasel = SeparateDateSelect(fecha)
      const id_cliente = document.getElementById('cliente').value;
      const tipo_peticion = 3      

      setAviso({
          Show:false
      })

      const config = {
         onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
         }
      };

      try {
         const { data } = await Axios.post('/coberturaseguridad.php', {
            fechasel, id_cliente, id_cobertura, tipo_peticion
         }, config);
         console.log("data-eliminar", data);
         if (data.success == 1) {
            BuscarCoincidencia()
         }
         if (data.success == 0) {
            console.log('No se pudo eliminar')
         }
         setProgress(0)
      } catch (err) {
         console.error("Error during submission:", err);
         setProgress(0)
      } finally {
         setProgress(0)
      }
      
      
   }

   return (
      <Suspense fallback={<Spinner />}>
         <>
            {ShowSpinner && <Spinner />}
            {progress > 0 &&
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-sm">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                           {/*header*/}
                           <div className="flex justify-center items-center rounded-t"></div>
                           {/*body*/}
                           <div className="w-full p-4 flex flex-col justify-center items-center text-white">
                              <BiLoaderAlt className="animate-spin" />
                              <label htmlFor="" className="text-white mb-1">Cargando...</label>
                              <div className="relative pt-1 w-full">
                                 <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white">
                                    <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-sky-400"></div>
                                 </div>
                              </div>
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-center rounded-b px-2"></div>
                        </div>
                     </div>
                  </div>
                  <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
               </>
            }
            {Aviso.Show &&
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     {/* <div className="relative w-auto my-6 mx-auto max-w-sm"> */}
                     <div className="relative my-6 mx-2 w-full md:w-7/10 lg:w-1/2">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                           {/*header*/}
                           <div className="flex justify-center items-center rounded-t">
                              {Aviso.Logo == "BsFillQuestionCircleFill" && <span className="p-2"><BsFillQuestionCircleFill className={`text-${Aviso.Color} text-5xl`} /></span>}
                              {Aviso.Logo == "BsFillCheckCircleFill" && <span className="p-2"><BsFillCheckCircleFill className={`text-${Aviso.Color} text-5xl`} /></span>}
                              {Aviso.Logo == "BsFillXCircleFill" && <span className="p-2"><BsFillXCircleFill className={`text-${Aviso.Color} text-5xl`} /></span>}
                           </div>
                           {/*body*/}
                           <div className="relative p-4 flex-auto text-white">
                              {Aviso.Texto &&
                                 <p className={`${(Aviso.AlignTexto ? "text-center" : "text-justify")} my-1 font-bold text-lg whitespace-pre-line`}>
                                    {Aviso.Texto}
                                 </p>
                              }
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-center rounded-b px-2">
                              <div className="flex items-center justify-around w-full">
                                 {Aviso.Botones.Bcerrar && <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => CerrarAviso(Aviso.Login)}>Cerrar</button>}
                                 {Aviso.Botones.Beliminar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={()=>EliminarCargarCobertura(Aviso.Vid)}>Si</button>}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
               </>
            }

            {user.status_usuario ?
               <>
                  {/* ############################### BODY ################################### */}
                  <div className={` ${LocacionHttp[3] != "home" && "w-screen"}`}>
                     <fieldset className={` ${LocacionHttp[3] != "home" ? "border-double border-4 border-sky-900 rounded-lg p-3 m-2 mb-10" : ""}`}>
                        <legend className={` ${LocacionHttp[3] != "home" ? "py-2 px-2 bg-sky-900 rounded-lg text-lg font-bold text-white" : "hidden"}`}><span className="mr-1">Cobertura de Puesto (Seguridad)</span></legend>

                        <Formik
                           innerRef={formikRef}
                           initialValues={{
                              fecha: '',
                              cliente: '',
                              entrada: '',
                              salida: '',
                              status_puesto: '',
                              colaborador: '',
                              observacion: ''
                           }}
                           validate={(valores) => {
                              let errores = {}
                              if (!valores.fecha) {
                                 errores.fecha = 'Por favor, seleccione una fecha'
                              }
                              if (!valores.cliente) {
                                 errores.cliente = 'Por favor, seleccione un item'
                              }
                              if (!valores.entrada) {
                                 errores.entrada = 'Por favor, seleccione un item'
                              }
                              if (!valores.salida) {
                                 errores.salida = 'Por favor, seleccione un item'
                              }
                              if (!valores.status_puesto) {
                                 errores.status_puesto = 'Por favor, seleccione un item'
                              }
                              if (!valores.colaborador) {
                                 errores.colaborador = 'Por favor, seleccione un item'
                              }
                              return errores
                           }}

                        >
                           {({ values, errors, touched, handleChange, handleBlur }) => (
                              <form>
                                 <div className="flex justify-between items-center m-4">
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="fecha" className="font-bold text-white mb-2">Fecha</label>
                                       <input
                                          type="date"
                                          id="fecha"
                                          name="fecha"
                                          value={values.fecha}
                                          className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                          onChange={event => { handleChange(event); ChangeFecha(event); }}
                                          onClick={(event) => {
                                             values.entrada = ""
                                             values.salida = ""
                                             values.cliente = ""
                                             setTableCoincidencia({ Show: false })
                                          }}
                                          onBlur={event => { handleBlur(event); }}
                                       />
                                       {touched.fecha && errors.fecha && <div className="text-sm font-semibold text-red-500 italic">{errors.fecha}</div>}
                                    </div>
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="entrada" className="font-bold text-white mb-2">Entrada</label>
                                       <input
                                          type="time"
                                          id="entrada"
                                          name="entrada"
                                          value={values.entrada}
                                          className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                          onChange={event => { handleChange(event); ChangeFecha(event); }}
                                          onClick={(event) => {
                                             values.cliente = ""
                                             values.salida = ""
                                             setTableCoincidencia({ Show: false })
                                          }}
                                          onBlur={event => { handleBlur(event); }}
                                       />
                                       {touched.entrada && errors.entrada && <div className="text-sm font-semibold text-red-500 italic">{errors.entrada}</div>}
                                    </div>
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="salida" className="font-bold text-white mb-2">Salida</label>
                                       <input
                                          type="time"
                                          id="salida"
                                          name="salida"
                                          value={values.salida}
                                          className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                          onChange={event => { handleChange(event); ChangeFecha(event); }}
                                          onClick={(event) => {
                                             values.cliente = ""
                                             setTableCoincidencia({ Show: false })
                                          }}
                                          onBlur={event => { handleBlur(event); }}
                                       />
                                       {touched.salida && errors.salida && <div className="text-sm font-semibold text-red-500 italic">{errors.salida}</div>}
                                    </div>
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="cliente" className="font-bold text-white mb-2">Cliente</label>
                                       <select
                                          id="cliente"
                                          name="cliente"
                                          value={values.cliente}
                                          className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                          onChange={event => { handleChange(event); ChangeCliente(event); }}
                                          onBlur={handleBlur}
                                       >
                                          <option value="">---Selección---</option>
                                          {ArrayCliente.length > 0 && (
                                             ArrayCliente.map(function (d, i) {
                                                return (
                                                   <option key={"cliente_" + i} value={d.id_cliente}>{d.nombre_cliente}</option>
                                                )
                                             })
                                          )}
                                       </select>
                                       {touched.cliente && errors.cliente && <div className="text-sm font-semibold text-red-500 italic">{errors.cliente}</div>}
                                    </div>
                                 </div>
                                 {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
                                 {TableCoincidencia.Show && (
                                    <>
                                       {ArraySucursales.length > 0 ? (
                                          ArraySucursales.map(function (d, i) {
                                             return (
                                                <div key={`sucursal_${i}`} className="rounded-lg bg-sky-900 py-1 px-1 m-1">
                                                   <div className="flex flex-row justify-between items-center m-2">
                                                      <div className="flex flex-col font-bold text-white"><span className="text-xs">{d.nombre_sucursal}</span></div>
                                                      <div className="flex flex-row justify-between items-center">
                                                         <div className="flex flex-col justify-center items-center mx-4">
                                                            <label htmlFor="" className="text-sm font-bold text-white">PAC</label>
                                                            <div id={"pac_" + i + ""} className="w-16 bg-yellow-500 p-1 rounded-lg text-center font-bold text-white">-</div>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-2">
                                                            <label htmlFor="" className="text-2xl font-bold text-white">=</label>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-2">
                                                            <label htmlFor="" className="text-sm font-bold text-white">PC</label>
                                                            <div id={"pc_" + i + ""} className="w-14 bg-green-900 p-1 rounded-lg text-center font-bold text-white">-</div>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-0.1">
                                                            <label htmlFor="" className="text-2xl font-bold text-white">+</label>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-2">
                                                            <label htmlFor="" className="text-sm font-bold text-white">PNC</label>
                                                            <div id={"pnc_" + i + ""} className="w-14 bg-red-900 p-1 rounded-lg text-center font-bold text-white">-</div>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-0.1">
                                                            <label htmlFor="" className="text-2xl font-bold text-white">+</label>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-2">
                                                            <label htmlFor="" className="text-sm font-bold text-white">SC</label>
                                                            <div id={"sc_" + i + ""} className="w-14 bg-blue-900 p-1 rounded-lg text-center font-bold text-white">-</div>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-0.1">
                                                            <label htmlFor="" className="text-2xl font-bold text-white">+</label>
                                                         </div>
                                                         <div className="flex flex-col justify-center items-center mx-2">
                                                            <label htmlFor="" className="text-sm font-bold text-white">N/A</label>
                                                            <div id={"na_" + i + ""} className="w-14 bg-orange-600 p-1 rounded-lg text-center font-bold text-white">-</div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div key={`div_puesto_${i}`} className="w-full bg-white p-2">
                                                      <>
                                                         <TablePuestos key={`puesto_${i}`} id_sucursal={d.id_sucursal} nombre_sucursal={d.nombre_sucursal} numero={i} />
                                                      </>
                                                   </div>
                                                   {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
                                                   {CargaPuesto.Show && (
                                                      <>
                                                         <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                                            <div className="relative w-full md:w-7/10 lg:w-1/2">
                                                               <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                                                                  <div className="flex justify-center items-center rounded-t">
                                                                     <span className="p-2"><BsFillClipboardCheckFill className="text-white text-5xl" /></span>
                                                                  </div>
                                                                  <div className="relative p-4 flex-auto text-white">
                                                                     <h1 className="text-2xl font-bold mb-4 text-center">CARGA DE PUESTO DE SERVICIO</h1>
                                                                     <div className="bg-white">
                                                                        <table className="min-w-full divide-y divide-gray-200">
                                                                           <thead className="bg-sky-900">
                                                                              <tr>
                                                                                 <th scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">SUCURSAL</th>
                                                                                 <th scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">PUESTO</th>
                                                                                 <th scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">HORARIO</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr>
                                                                                 <td className="px-2 py-0.5 text-xs border border-slate-300 text-black text-center">{CargaPuesto.nombre_sucursal}</td>
                                                                                 <td className="px-2 py-0.5 text-xs border border-slate-300 text-black text-center">{CargaPuesto.nombre_puesto}</td>
                                                                                 <td className="px-2 py-0.5 text-xs border border-slate-300 text-black text-center">E:{CargaPuesto.entrada} - S:{CargaPuesto.salida}</td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                        <table className="min-w-full divide-y divide-gray-200">
                                                                           <thead className="bg-sky-900">
                                                                              <tr>
                                                                                 <th width="20%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">STATUS</th>
                                                                                 <th width="auto%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">COLABORADOR</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr>
                                                                                 <td className="text-xs border border-slate-300 text-black text-center">
                                                                                    <select
                                                                                       name="status_puesto"
                                                                                       id="status_puesto"
                                                                                       className="form-select block w-full px-1 py-0.5 text-sm font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                                                                       value={values.status_puesto}
                                                                                       onChange={event => { handleChange(event); ChangeStatus(event); }}
                                                                                       onClick={(event) => {
                                                                                          values.colaborador = ""
                                                                                       }}
                                                                                       onBlur={handleBlur}
                                                                                    >
                                                                                       <option value="">--</option>
                                                                                       <option value={1}>PC</option>
                                                                                       <option value={2}>PNC</option>
                                                                                       <option value={3}>SC</option>
                                                                                       <option value={4}>N/A</option>
                                                                                    </select>
                                                                                    {touched.status_puesto && errors.status_puesto && <div className="text-sm font-semibold text-red-500 italic">{errors.status_puesto}</div>}
                                                                                 </td>
                                                                                 <td className="text-xs border border-slate-300 text-black text-center">
                                                                                    {TipoStatus.Show &&
                                                                                       <select
                                                                                          name="colaborador"
                                                                                          id="colaborador"
                                                                                          className="form-select block w-full px-1 py-0.5 text-sm font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                                                                          value={values.colaborador}
                                                                                          onChange={event => { handleChange(event); }}
                                                                                          onBlur={handleBlur}
                                                                                       >
                                                                                          <>
                                                                                             {TipoStatus.valor == 1 &&
                                                                                                <>
                                                                                                   <option value="">--</option>
                                                                                                   {/* {ArrayPersonal.map(function(dc,ic) {
                                                                                                               if (!ArrayCedulaCobertura.includes(dc.id_personal)) {
                                                                                                                  return (
                                                                                                                     <option value={dc.id_personal} title={""+dc.nombres_personal+" "+dc.apellidos_personal+"\n"+dc.nombre_cargo+"\n"+new Intl.NumberFormat('es-ES').format(dc.nrocedula_personal)+""}>{dc.nombres_personal} {dc.apellidos_personal}</option>
                                                                                                                  )
                                                                                                               }
                                                                                                            })} */}
                                                                                                   {ArrayPersonal.map(function (dc, ic) {
                                                                                                      return (
                                                                                                         <option value={dc.id_personal} title={"" + dc.nombres_personal + " " + dc.apellidos_personal + "\n" + dc.nombre_cargo + "\n" + new Intl.NumberFormat('es-ES').format(dc.nrocedula_personal) + ""}>{dc.nombres_personal} {dc.apellidos_personal}</option>
                                                                                                      )
                                                                                                   })}
                                                                                                </>
                                                                                             }
                                                                                             {TipoStatus.valor == 2 &&
                                                                                                <>
                                                                                                   <option value="">--</option>
                                                                                                   <option value={0}>PUESTO NO CUBIERTO</option>
                                                                                                </>
                                                                                             }
                                                                                             {TipoStatus.valor == 3 &&
                                                                                                <>
                                                                                                   <option value="">--</option>
                                                                                                   <option value={0}>PUESTO SIN COMUNICACIÓN</option>
                                                                                                </>
                                                                                             }
                                                                                             {TipoStatus.valor == 4 &&
                                                                                                <>
                                                                                                   <option value="">--</option>
                                                                                                   <option value={0}>NO APLICA</option>
                                                                                                </>
                                                                                             }
                                                                                          </>
                                                                                       </select>
                                                                                    }
                                                                                    {touched.colaborador && errors.colaborador && <div className="text-sm font-semibold text-red-500 italic">{errors.colaborador}</div>}
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                        <table className="min-w-full divide-y divide-gray-200">
                                                                           <thead className="bg-sky-900">
                                                                              <tr>
                                                                                 <th scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">OBSERVACIONES</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr>
                                                                                 <td className="px-2 py-0.5 text-xs border border-slate-300 text-black text-center">
                                                                                    <textarea
                                                                                       name="observacion"
                                                                                       id="observacion"
                                                                                       cols="30"
                                                                                       rows="5"
                                                                                       className="form-select block w-full px-1 py-0.5 text-sm font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                                                                       value={values.observacion}
                                                                                       onChange={event => { handleChange(event); }}
                                                                                       onBlur={handleBlur}
                                                                                    >
                                                                                    </textarea>
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div className="flex items-center justify-center rounded-b px-1">
                                                                     <div className="flex items-center justify-around w-full">
                                                                        <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={CerrarAviso}>Cerrar</button>
                                                                        {((TipoStatus.valor >= 1 && TipoStatus.valor <= 4) && values.colaborador) &&
                                                                           <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={() => CargarPuesto()}>Cargar</button>
                                                                        }
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
                                                      </>
                                                   )}
                                                   {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}

                                                </div>
                                             )
                                          })
                                       ) :
                                          <div className='flex justify-center items-center font-bold text-red-500'>No hay Estructura</div>
                                       }
                                    </>
                                 )}
                                 {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
                              </form>
                           )}
                        </Formik>

                     </fieldset>
                  </div>
                  {/* ############################### FIN BODY ################################### */}
               </>
               :
               <Nousuario user={user.email_usuario} />
            }
         </>
      </Suspense>
   )
}

export default CoberturaPuestoSeguridad