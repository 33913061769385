import {useContext, useState, useEffect, Suspense} from 'react'
import {UserContext} from '../context/UserContext'
import LogoA from '../resourse/img/logoA.png'
import Aavatar from '../resourse/img/A_avatar.png'
import { BsFillCpuFill, BsFillLockFill, BsFillQuestionCircleFill, BsFillMenuButtonWideFill, BsTextIndentLeft } from "react-icons/bs"
import Spinner from "./modal/Spinner";
import RecursosHumanos from './modulos/RecursosHumanos'
import Operaciones from './modulos/Operaciones'
import ControlComensal from './funciones/operaciones/ControlComensal'
import ControlServicio from './funciones/operaciones/ControlServicio'
import DiaFechaHora from "./funciones/diafechahora"
import Footer from "./bloque_home/Footer"
import Nousuario from "./modal/NoUsuario";

const Home = () => {
    const {user, loggedInCheck, logout} = useContext(UserContext)
    const [LocacionHttp, setLocacionHttp] = useState(window.location.href.split("/"));
    const [OpenSidebar, setOpenSidebar] = useState(false)
    const [OpenModulo, setOpenModulo] = useState("")

    const [Aviso, setAviso] = useState({
        Show:false,
    })

    const OpenDivModulo = (value) =>{
        setOpenModulo(OpenModulo == value ? "" : value)
    }

    const CerrarSesion = async(email) => {
        const data = await logout({
            email
        });
    }

    return (
        <Suspense fallback={<Spinner />}>
            <>
                {user.status_usuario ?
                    <>
                        {Aviso.Show &&
                            <>
                                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                    <div className="relative w-auto my-6 mx-auto max-w-sm">
                                        {/*content*/}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                                        {/*header*/}
                                        <div className="flex justify-center items-center rounded-t">
                                            <span className="p-2"><BsFillQuestionCircleFill className="text-orange-400 text-5xl"/></span>
                                        </div>
                                        {/*body*/}
                                        <div className="relative p-4 flex-auto text-white">
                                            <p className="my-1 text-center font-bold text-2xl whitespace-pre-line">
                                                {Aviso.Texto}
                                            </p>
                                        </div>
                                        {/*footer*/}
                                        <div className="flex items-center justify-between rounded-b px-2">
                                            <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 transition-all ease-in duration-150" type="button" onClick={() => setAviso({Show:false})}>No</button>
                                            <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 transition-all ease-in duration-150" type="button" onClick={() => CerrarSesion(user.email_usuario)}>Si</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
                            </>
                        }

                        {/* ############################### BODY ################################### */}
                        <div className="flex flex-row min-h-screen w-screen">
                            {/* MODULOS */}
                            <div className={` ${OpenSidebar ? "w-10" : "w-96"} bg-sky-900 p-1 pb-8 shadow ease-in-out duration-300`}>
                                <div className="space-y-0 mb-2">
                                    <button className="w-full shadow" onClick={() => setOpenSidebar(!OpenSidebar)}>
                                        <div className={` ${OpenSidebar ? "hidden justify-center" : "inline justify-between bg-white"} flex flex-col items-center rounded-lg ease-in duration-100`}>
                                            <div className={` ${OpenSidebar ? "hidden" : "inline"} flex flex-col justify-center items-center text-xs font-bold m-1 ease-in duration-100 flex-nowrap`}>
                                                <div className="flex flex-row justify-between items-center mb-2">
                                                    <img id="avatarButton" type="button" className="h-10 cursor-pointer mr-2" src={Aavatar} />
                                                    <span className="font-bold"><DiaFechaHora/></span>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <div>{user.nombres_usuario} {user.apellidos_usuario}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {OpenSidebar &&
                                            <span className="ease-in-out duration-300 text-white text-4xl font-extrabold"><BsTextIndentLeft /></span>
                                        }
                                    </button>
                                </div>
                                {/* BOTON MODULOS */}
                                {!OpenSidebar &&
                                    <div className="ease-in-out duration-300">
                                        <ul className="space-y-0 text-sm">
                                            {(user.V_T || user.M_2) ?
                                                <li className="rounded-sm">
                                                    <a
                                                        onClick={()=> OpenDivModulo("RecursosHumanos")}
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-pointer  hover:bg-sky-700"
                                                    >
                                                        <span className="text-white font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-white font-bold">Recursos Humanos</span>
                                                    </a>
                                                </li>
                                                :
                                                <li className="rounded-sm">
                                                    <a
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-not-allowed"
                                                    >
                                                        <span className="text-gray-400 font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-gray-400 font-bold">Recursos Humanos</span>
                                                    </a>
                                                </li>
                                            }
                                            {(user.V_T || user.M_3) ?
                                                <li className="rounded-sm">
                                                    <a
                                                        onClick={()=> OpenDivModulo("Operaciones")}
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-pointer  hover:bg-sky-700"
                                                    >
                                                        <span className="text-white font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-white font-bold">Operaciones</span>
                                                    </a>
                                                </li>
                                                :
                                                <li className="rounded-sm">
                                                    <a
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-not-allowed"
                                                    >
                                                        <span className="text-gray-400 font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-gray-400 font-bold">Operaciones</span>
                                                    </a>
                                                </li>
                                            }
                                            {(user.V_T || user.M_4) ?
                                                <li className="rounded-sm">
                                                    <a
                                                        onClick={()=> OpenDivModulo("ControlComensal")}
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-pointer  hover:bg-sky-700"
                                                    >
                                                        <span className="text-white font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-white font-bold">Control Comensal</span>
                                                    </a>
                                                </li>
                                                :
                                                <li className="rounded-sm">
                                                    <a
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-not-allowed"
                                                    >
                                                        <span className="text-gray-400 font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-gray-400 font-bold">Control Comensal</span>
                                                    </a>
                                                </li>
                                            }
                                            {(user.V_T || user.M_5) ?
                                                <li className="rounded-sm">
                                                    <a
                                                        onClick={()=> OpenDivModulo("ControlServicio")}
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-pointer  hover:bg-sky-700"
                                                    >
                                                        <span className="text-white font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-white font-bold">Control Servicio</span>
                                                    </a>
                                                </li>
                                                :
                                                <li className="rounded-sm">
                                                    <a
                                                        className="flex items-center p-2 space-x-3 rounded-md cursor-not-allowed"
                                                    >
                                                        <span className="text-gray-400 font-bold text-lg"><BsFillCpuFill/></span>
                                                        <span className="text-gray-400 font-bold">Control Servicio</span>
                                                    </a>
                                                </li>
                                            }
                                            <li className="rounded-sm">
                                                <a
                                                    //href="#"
                                                    onClick={() => setAviso({ Show:true,Texto:"¿Desea cerrar sesión?" })}
                                                    className="flex items-center p-2 space-x-3 rounded-md cursor-pointer  hover:bg-sky-700"
                                                >
                                                    <span className="text-white font-bold text-lg"><BsFillLockFill/></span>
                                                    <span className="text-white font-bold">Cerrar Sesión</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {/* FIN BOTON MODULOS */}
                            </div>
                            {/* FIN MODULOS */}

                            {/* FUNCIONES */}
                            {OpenModulo=="" && <div className="flex flex-col w-full" style={{backgroundImage: `url(${LogoA})`}}></div>}
                            {OpenModulo!="" &&
                                <div className="flex flex-col w-full">
                                    {(user.V_T || user.M_2) ?
                                        <>
                                            {OpenModulo=="RecursosHumanos" && 
                                                <RecursosHumanos/>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                    {(user.V_T || user.M_3) ?
                                        <>
                                            {OpenModulo=="Operaciones" && 
                                                <Operaciones/>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                    {(user.V_T || user.M_4) ?
                                        <>
                                            {OpenModulo=="ControlComensal" && 
                                                <ControlComensal/>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                    {(user.V_T || user.M_5) ?
                                        <>
                                            {OpenModulo=="ControlServicio" && 
                                                <ControlServicio/>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </div>
                            }
                            {/* FIN FUNCIONES */}
                        </div>
                        {/* ################################# FIN BODY ######################################### */}
                        <Footer/>
                    </>
                :
                    <Nousuario user={user.email_usuario}/>
                
                }
            </>
        </Suspense>
    )
}

export default Home;