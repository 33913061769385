import {useContext, useState, useEffect, Suspense} from 'react'
import {Navigate} from 'react-router-dom';
import {UserContext} from '../../../context/UserContext'
import LogoA from '../../../resourse/img/logoA.png'
import { BsFillTrashFill, BsArrowRepeat, BsFillArrowUpSquareFill, BsFillArrowDownSquareFill, BsFillPinMapFill, BsFillCloudUploadFill, BsFillClockFill, BsFillClipboardCheckFill, BsFillPeopleFill, BsFillBinocularsFill, BsFillPrinterFill, BsFillQuestionCircleFill, BsFillCheckCircleFill, BsFillXCircleFill} from "react-icons/bs"
import { Formik } from "formik"
import Spinner from "../../modal/Spinner";
import Nousuario from "../../modal/NoUsuario";
import { Axios } from '../../../context/UserContext';

/* import { Collapse,initTWE } from "tw-elements"; 
initTWE({ Collapse }); */

const AgregarPersonal = (props) => {
   const {user, loggedInCheck, logout} = useContext(UserContext)
   const [LocacionHttp, setLocacionHttp] = useState(window.location.href.split("/"));
   const [ShowSpinner, setShowSpinner] = useState(false)
   const [Aviso, setAviso] = useState({Show:false})
   const [ArrayCliente, setArrayCliente] = useState({})
   const [DataPersonalConsulta, setDataPersonalConsulta] = useState({Show:false})
   const [DataPersonalAgregar, setDataPersonalAgregar] = useState({Show:false})
   const [DataPersonalExiste, setDataPersonalExiste] = useState({Show:false})
   const [DataPersonalActualizar, setDataPersonalActualizar] = useState({Show:false})
   const [DataConsulta, setDataConsulta] = useState({})
   const [OpenFuncion, setOpenFuncion] = useState("")
   const OpenDivFuncion = (value) =>{setOpenFuncion(OpenFuncion == value ? "" : value)}
   const CerrarAviso = (login) => {
      setAviso({Show:false})
      if(login){
         <Navigate to="/login" />
      }
   }

   const ConsultarPersonal = async(e)=>{
      setShowSpinner(true)
      const user_activo = await loggedInCheck({});
      if(user_activo){
         try{
            const {data} = await Axios.post('/personal/consultageneral.php',{});
            setDataPersonalConsulta({
               Show:true,
               Datos:data.datos
            })
            setShowSpinner(false)
         } catch(err){ console.log("err");console.log(err) }
         try{
            const {data} = await Axios.post('/personal/consulta/sexo.php',{});
            setDataConsulta({
               Show:true,
               Sexo:data.datos
            })
         } catch(err){ console.log("err:sexo"); console.log(err) }
      }

   }

   const ChangeCedula = async(e)=>{
      const cedula = document.getElementById('cedula').value;
      const user_activo = await loggedInCheck({});      
      if(user_activo && cedula){
         try{
            const {data} = await Axios.post('/personal/consultarcedula.php',{
                  cedula
            });
            if(data.success==0){
               setDataPersonalAgregar({
                  Show:true,
                  cedula:cedula
               })
            }else if(data.success==1){
               setDataPersonalExiste({
                  Show:true,
                  Datos:data.datos
               })
            }
            console.log(data)
         }catch(err){ console.log("err"); console.log(err) }
   
         try{
            const {data} = await Axios.post('/personal/consulta/sexo.php',{});
            setDataConsulta({
               Show:true,
               Sexo:data.datos
            })
         } catch(err){ console.log("err:sexo"); console.log(err) }
      }

   }

   const AgregarDatos = (datos)=>{
      let dat=datos.datos
      return(
         <Formik
            initialValues={{
               cedula:dat.cedula,
               primernombre:'',
               segundonombre:'',
               primerapellido:'',
               segundoapellido:'',
               id_sexo:'',
            }}
            validate={(valores) =>{
               let errores={}

               if(!valores.primernombre){
                  errores.primernombre='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.primernombre)){
                  errores.primernombre='Por favor, ingrese un valor válido'
               }
               if(!valores.segundonombre){
                  errores.segundonombre='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.segundonombre)){
                  errores.segundonombre='Por favor, ingrese un valor válido'
               }
               if(!valores.primerapellido){
                  errores.primerapellido='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.primerapellido)){
                  errores.primerapellido='Por favor, ingrese un valor válido'
               }
               if(!valores.segundoapellido){
                  errores.segundoapellido='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.segundoapellido)){
                  errores.segundoapellido='Por favor, ingrese un valor válido'
               }
               if(!valores.id_sexo || (parseInt(valores.id_sexo) <1 || parseInt(valores.id_sexo) >2)){
                  errores.id_sexo='Por favor, seleccione un valor'
               }

               return errores
            }}
            onSubmit= {async (valores,{resetForm}) =>{
               const user_activo = await loggedInCheck({});

               try{
                  setShowSpinner(true)
                  let proceso= 0;
                  const {data} = await Axios.post('/personal/agregaractualizarpersonal.php',{
                     valores, proceso, user_activo
                  });
                  if(data.success==1){
                     setAviso({
                        Show:true,
                        Logo:'BsFillCheckCircleFill',
                        Color:'green-500',
                        Texto:"Información agregada con éxito",
                        Login:'login',
                        AlignTexto:true,
                        Botones:{
                           Bcerrar:true,
                           Benviar:false
                        }
                     })
                     setDataPersonalAgregar({
                        Show:false
                     })
                     resetForm();
                  }else{
                     setAviso({
                        Show:true,
                        Logo:'BsFillXCircleFill',
                        Color:'red-500',
                        Texto:data.message.replace(/\\n/g, '\n'),
                        Login:'login',
                        AlignTexto:true,
                        Botones:{
                           Bcerrar:true,
                           Benviar:false
                        }
                     })
                  }
                  setShowSpinner(false)
               }catch(err){ console.log("err"); console.log(err) }
            }}
         >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) =>(
               <form onSubmit={handleSubmit}>
                  <table className="min-w-full divide-y divide-gray-200">
                     <thead className="bg-sky-900">
                        <tr>
                           <th width="14%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">CÉDULA</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">PRIMER NOMBRE</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">SEGUNDO NOMBRE</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">PRIMER APELLIDO</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">SEGUNDO APELLIDO</th>
                           <th width="10%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">SEXO</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td className="text-xs border border-slate-300 text-black text-center font-bold bg-gray-300">{values.cedula}</td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="primernombre" 
                                 name="primernombre"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="PRIMER NOMBRE"
                                 value={values.primernombre}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.primernombre && errors.primernombre && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.primernombre}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="segundonombre" 
                                 name="segundonombre"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="SEGUNDO NOMBRE"
                                 value={values.segundonombre}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.segundonombre && errors.segundonombre && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.segundonombre}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="primerapellido" 
                                 name="primerapellido"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="PRIMER APELLIDO"
                                 value={values.primerapellido}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.primerapellido && errors.primerapellido && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.primerapellido}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="segundoapellido" 
                                 name="segundoapellido"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="SEGUNDO APELLIDO"
                                 value={values.segundoapellido}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.segundoapellido && errors.segundoapellido && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.segundoapellido}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <select 
                                 name="id_sexo"
                                 id="id_sexo"
                                 className="form-select block w-full px-2 py-2.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 value={values.id_sexo}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={handleBlur}
                              >
                                 <option value="">...</option>
                                 {DataConsulta.Show && 
                                    DataConsulta.Sexo.map(function(d,i) {
                                       return (
                                          <option key={i} value={d.id_sexo} title={d.nombre_sexo}>{d.letra_sexo}</option>
                                       )
                                    })
                                 }
                              </select>
                              {touched.id_sexo && errors.id_sexo && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.id_sexo}</div>}
                           </td>
                        </tr>
                     </tbody>
                  </table>

                  <div className='flex justify-center items-center mt-4'><button type="submit" className="text-xl font-extrabold p-2 tracking-wide text-white transition-colors duration-200 transform bg-sky-900 rounded-md hover:bg-sky-700">AGREGAR</button></div>

               </form>
            )}
         </Formik>
      )
   }

   const ActualizarDatos = (datos, ShowFieldeset)=>{
      let dat=datos.datos.Datos
      return(
         <Formik
            initialValues={{
               cedula:dat["cedula"],
               primernombre:dat["primernombre"],
               segundonombre:dat["segundonombre"],
               primerapellido:dat["primerapellido"],
               segundoapellido:dat["segundoapellido"],
               id_sexo:dat["id_sexo"],
               nombre_sexo:dat["nombre_sexo"],
               letra_sexo:dat["letra_sexo"],
            }}
            validate={(valores) =>{
               let errores={}

               if(!valores.primernombre){
                  errores.primernombre='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.primernombre)){
                  errores.primernombre='Por favor, ingrese un valor válido'
               }
               if(!valores.segundonombre){
                  errores.segundonombre='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.segundonombre)){
                  errores.segundonombre='Por favor, ingrese un valor válido'
               }
               if(!valores.primerapellido){
                  errores.primerapellido='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.primerapellido)){
                  errores.primerapellido='Por favor, ingrese un valor válido'
               }
               if(!valores.segundoapellido){
                  errores.segundoapellido='Por favor, ingrese un valor'
               }else if(!/^[A-Za-z]+$/.test(valores.segundoapellido)){
                  errores.segundoapellido='Por favor, ingrese un valor válido'
               }
               if(parseInt(valores.id_sexo) <1 || parseInt(valores.id_sexo) >2){
                  errores.id_sexo='Por favor, seleccione un valor'
               }
               return errores
            }}
            onSubmit= {async (valores,{resetForm}) =>{
               const user_activo = await loggedInCheck({});
               let keys=0;
               let coincidencia=0;
               Object.entries(valores).forEach(([key_A, value_A]) => {
                  keys++
                  Object.entries(DataPersonalActualizar.Datos).forEach(([key_B, value_B]) => {
                     if((key_A==key_B) && (value_A==value_B)){coincidencia++}
                  });
               });
               
               if(keys!==coincidencia){
                  try{
                     setShowSpinner(true)
                     let proceso=1;
                     const {data} = await Axios.post('/personal/agregaractualizarpersonal.php',{
                        valores, proceso, user_activo
                     });
                     if(data.success==1){
                        setAviso({
                           Show:true,
                           Logo:'BsFillCheckCircleFill',
                           Color:'green-500',
                           Texto:"Información actualizada con éxito",
                           Login:'',
                           AlignTexto:true,
                           Botones:{
                              Bcerrar:true,
                              Benviar:false
                           }
                        })
                        setDataPersonalActualizar({
                           Show:false
                        })
                        resetForm();
                        if(ShowFieldeset){ConsultarPersonal()}
                     }else{
                        setAviso({
                           Show:true,
                           Logo:'BsFillXCircleFill',
                           Color:'red-500',
                           Texto:data.message.replace(/\\n/g, '\n'),
                           Login:'login',
                           AlignTexto:true,
                           Botones:{
                              Bcerrar:true,
                              Benviar:false
                           }
                        })
                     }
                     setShowSpinner(false)
                  }catch(err){ console.log("err"); console.log(err) }
               }else{
                  setAviso({
                     Show:true,
                     Logo:'BsFillCheckCircleFill',
                     Color:'green-500',
                     Texto:"No es necesario Actualizar",
                     AlignTexto:true,
                     Botones:{
                        Bcerrar:true,
                        Benviar:false
                     }
                  })
                  setShowSpinner(false)
                  setDataPersonalActualizar({
                     Show:false
                  })
                  resetForm();
               }
            }}
         >
            {({values, errors, touched, handleSubmit, handleChange, handleBlur}) =>(
               <form onSubmit={handleSubmit}>
                  <table className="min-w-full divide-y divide-gray-200">
                     <thead className="bg-sky-900">
                        <tr>
                           <th width="14%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">CÉDULA ACTUALIZAR</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">PRIMER NOMBRE</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">SEGUNDO NOMBRE</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">PRIMER APELLIDO</th>
                           <th width="19%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">SEGUNDO APELLIDO</th>
                           <th width="10%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">SEXO</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td className="text-xs border border-slate-300 text-black text-center font-bold bg-gray-300">{values.cedula}</td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="primernombre" 
                                 name="primernombre"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="PRIMER NOMBRE"
                                 value={values.primernombre}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.primernombre && errors.primernombre && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.primernombre}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="segundonombre" 
                                 name="segundonombre"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="SEGUNDO NOMBRE"
                                 value={values.segundonombre}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.segundonombre && errors.segundonombre && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.segundonombre}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="primerapellido" 
                                 name="primerapellido"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="PRIMER APELLIDO"
                                 value={values.primerapellido}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.primerapellido && errors.primerapellido && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.primerapellido}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <input 
                                 type="text" 
                                 id="segundoapellido" 
                                 name="segundoapellido"
                                 className="form-select block w-full px-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                 placeholder="SEGUNDO APELLIDO"
                                 value={values.segundoapellido}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={event =>{handleBlur(event);}}
                              />
                              {touched.segundoapellido && errors.segundoapellido && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.segundoapellido}</div>}
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center">
                              <select 
                                 name="id_sexo"
                                 id="id_sexo"
                                 value={values.id_sexo}
                                 onChange={event=>{ handleChange(event);}}
                                 onBlur={handleBlur}
                                 className="form-select block w-full px-2 py-2.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                              >
                                 
                                 {values.id_sexo==0 && <option value="">...</option>}
                                 {DataConsulta.Show && 
                                    DataConsulta.Sexo.map(function(d,i) {
                                       return (
                                          <option key={i} value={parseInt(d.id_sexo)} title={d.nombre_sexo}>{d.letra_sexo}</option>
                                       )
                                    })
                                 }
                              </select>
                              {touched.id_sexo && errors.id_sexo && <div className="text-left text-xs  font-semibold text-red-500 italic px-2">{errors.id_sexo}</div>}
                           </td>
                        </tr>
                     </tbody>
                  </table>

                  <div className='flex justify-center items-center mt-4'><button type="submit" className="text-xl font-extrabold p-2 tracking-wide text-white transition-colors duration-200 transform bg-sky-900 rounded-md hover:bg-sky-700">ACTUALIZAR</button></div>
               </form>
            )}
         </Formik>
      )
   }

   const PersonalExiste = (datos)=>{
      let dat=datos.datos.Datos
      return(
         <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-sky-900">
               <tr>
                  <th width="12%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">CÉDULA</th>
                  <th width="30%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">NOMBRES</th>
                  <th width="30%" scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white">APELLIDOS</th>
                  <th width="10%" colSpan={3} scope="col" className="p-3 text-xs border border-slate-300 font-bold text-center text-white"></th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td className="text-xs border border-slate-300 text-black text-center font-bold px-2">{dat["cedula"]}</td>
                  <td className="text-xs border border-slate-300 text-black text-left font-bold px-2">{dat["primernombre"]} {dat["segundonombre"]}</td>
                  <td className="text-xs border border-slate-300 text-black text-left font-bold px-2">{dat["primerapellido"]} {dat["segundoapellido"]}</td>
                  <td className="text-xs border border-slate-300 text-black text-center font-bold px-2">
                     <button className="p-1 rounded text-2xl text-black hover:bg-sky-900 hover:text-white" title="Ver"><BsFillBinocularsFill/></button>
                  </td>
                  <td className="text-xs border border-slate-300 text-black text-center font-bold px-2">
                     <button className="p-1 rounded text-2xl text-black hover:bg-sky-900 hover:text-white" title="Actualizar" onClick={()=>{setDataPersonalActualizar({Show:true,Datos:DataPersonalExiste.Datos}); setDataPersonalExiste({Show:false})}}><BsArrowRepeat/></button>
                  </td>
                  <td className="text-xs border border-slate-300 text-black text-center font-bold px-2">
                     <button className="p-1 rounded text-2xl text-black hover:bg-sky-900 hover:text-white" title="Eliminar"><BsFillTrashFill/></button>
                  </td>
                  
               </tr>
            </tbody>
         </table>
      )
   }

   const PersonalConsulta = (datos)=>{
      let dat=[]
      datos.datos.Datos.forEach(element => {
         if(ArrayCliente.some(obj => obj.id_cliente == element.id_cliente)){
            dat.push(element)
         }
      });
      {/* <table className="min-w-full divide-y divide-gray-200 my-2">
         <thead className="bg-sky-900">
            <tr>
               <th width="5%" scope="col" className="p-3 text-sm border border-slate-300 font-bold text-center text-white">CÉDULA</th>
               <th width="20%" scope="col" className="p-3 text-sm border border-slate-300 font-bold text-center text-white">NOMBRES Y APELLIDOS</th>
               {ArrayCliente.length >1 &&
                  <th width="20%" scope="col" className="p-3 text-sm border border-slate-300 font-bold text-center text-white">CLIENTE</th>
               }
               <th width="5%" colSpan={3} scope="col" className="p-3 text-sm border border-slate-300 font-bold text-center text-white"></th>
            </tr>
         </thead>
         <tbody>
            {dat.length>0 ? 
               dat.map(function(d,i) {
                     return (
                        <tr key={i} className='hover:bg-slate-200' title={`${d["nrocedula_personal"]}\n${d["nombres_personal"]} ${d["apellidos_personal"]}`}>
                           <td className="text-xs border border-slate-300 text-black text-center px-2">{d["nrocedula_personal"]}</td>
                           <td className="text-xs border border-slate-300 text-black text-left px-2">
                              <div>{d["nombres_personal"]} {d["apellidos_personal"]}</div>
                              <div>{d["nombre_cargo"]}</div>
                           </td>
                           {ArrayCliente.length >1 &&
                              <td className="text-xs border border-slate-300 text-black text-center px-2">{d["nombre_cliente"]}</td>
                           }
                           <td className="text-xs border border-slate-300 text-black text-center font-bold px-2">
                              <button className="p-1 rounded text-2xl text-black hover:bg-sky-900 hover:text-white" title="Ver"><BsFillBinocularsFill/></button>
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center font-bold px-2">
                              {(user.V_T || user.F_2) ?
                                 <button className="p-1 rounded text-2xl text-black hover:bg-sky-900 hover:text-white" title="Actualizar" onClick={()=>{ setDataPersonalConsulta({Show:false}); setDataPersonalActualizar({Show:true, Datos:d, ShowFieldeset:true}); }}><BsArrowRepeat/></button>
                                 :
                                 <button className="p-1 rounded text-2xl text-red-500 hover:bg-red-500 hover:text-white"><BsArrowRepeat/></button>
                              }
                           </td>
                           <td className="text-xs border border-slate-300 text-black text-center font-bold px-2">
                              {(user.V_T || user.F_2) ?
                                 <button className="p-1 rounded text-2xl text-black hover:bg-sky-900 hover:text-white" title="Eliminar"><BsFillTrashFill/></button>
                                 :
                                 <button className="p-1 rounded text-2xl text-red-500 hover:bg-red-500 hover:text-white" onClick={()=>{AccesoDenegado()}}><BsFillTrashFill/></button>
                              }
                           </td>
                        </tr>
                     )
               })
               :
               <tr>
                     <td colSpan={7} className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span>No hay coincidencias</span></td>
               </tr>
            }
         </tbody>
      </table> */}
      return(
         <>
            <div id="accordionExample5">
            <div
            className="rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-body-dark">
            <h2 className="mb-0" id="headingOne5">
               <button
                  className="group relative flex w-full items-center rounded-t-lg border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-primary [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                  type="button"
                  data-twe-collapse-init
                  data-twe-target="#collapseOne5"
                  aria-expanded="true"
                  aria-controls="collapseOne5">
                  Accordion Item #1
                  <span
                  className="-me-1 ms-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke-width="1.5"
                     stroke="currentColor">
                     <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                  </span>
               </button>
            </h2>
            <div
               id="collapseOne5"
               className="!visible"
               data-twe-collapse-item
               data-twe-collapse-show
               aria-labelledby="headingOne5">
               <div className="px-5 py-4">
                  <strong>This is the first item's accordion body.</strong> It is
                  shown by default, until the collapse plugin adds the appropriate
                  classes that we use to style each element. These classes control
                  the overall appearance, as well as the showing and hiding via CSS
                  transitions. You can modify any of this with custom CSS or
                  overriding our default variables. It's also worth noting that just
                  about any HTML can go within the <code>.accordion-body</code>,
                  though the transition does limit overflow.
               </div>
            </div>
            </div>
            <div
            className="border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-body-dark">
            <h2 className="mb-0" id="headingTwo5">
               <button
                  className="group relative flex w-full items-center rounded-none border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-primary [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10 "
                  type="button"
                  data-twe-collapse-init
                  data-twe-collapse-collapsed
                  data-twe-target="#collapseTwo5"
                  aria-expanded="false"
                  aria-controls="collapseTwo5">
                  Accordion Item #2
                  <span
                  className="-me-1 ms-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6 ">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke-width="1.5"
                     stroke="currentColor">
                     <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                  </span>
               </button>
            </h2>
            <div
               id="collapseTwo5"
               className="!visible hidden"
               data-twe-collapse-item
               aria-labelledby="headingTwo5">
               <div className="px-5 py-4">
                  <strong>This is the second item's accordion body.</strong> It is
                  hidden by default, until the collapse plugin adds the appropriate
                  classes that we use to style each element. These classes control
                  the overall appearance, as well as the showing and hiding via CSS
                  transitions. You can modify any of this with custom CSS or
                  overriding our default variables. It's also worth noting that just
                  about any HTML can go within the <code>.accordion-body</code>,
                  though the transition does limit overflow.
               </div>
            </div>
            </div>
            <div
            className="rounded-b-lg border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-body-dark">
            <h2 className="mb-0" id="headingThree5">
               <button
                  className="group relative flex w-full items-center border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none data-[twe-collapse-collapsed]:rounded-b-lg data-[twe-collapse-collapsed]:transition-none dark:bg-body-dark dark:text-white [&:not([data-twe-collapse-collapsed])]:bg-white [&:not([data-twe-collapse-collapsed])]:text-primary [&:not([data-twe-collapse-collapsed])]:shadow-border-b dark:[&:not([data-twe-collapse-collapsed])]:bg-surface-dark  dark:[&:not([data-twe-collapse-collapsed])]:text-primary dark:[&:not([data-twe-collapse-collapsed])]:shadow-white/10"
                  type="button"
                  data-twe-collapse-init
                  data-twe-collapse-collapsed
                  data-twe-target="#collapseThree5"
                  aria-expanded="false"
                  aria-controls="collapseThree5">
                  Accordion Item #3
                  <span
                  className="-me-1 ms-auto h-5 w-5 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6">
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24"
                     stroke-width="1.5"
                     stroke="currentColor">
                     <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                  </span>
               </button>
            </h2>
            <div
               id="collapseThree5"
               className="!visible hidden"
               data-twe-collapse-item
               aria-labelledby="headingThree5">
               <div className="px-5 py-4">
                  <strong>This is the third item's accordion body.</strong> It is
                  hidden by default, until the collapse plugin adds the appropriate
                  classes that we use to style each element. These classes control
                  the overall appearance, as well as the showing and hiding via CSS
                  transitions. You can modify any of this with custom CSS or
                  overriding our default variables. It's also worth noting that just
                  about any HTML can go within the <code>.accordion-body</code>,
                  though the transition does limit overflow.
               </div>
            </div>
            </div>
            </div>
         </>
      )
   }

   const AccesoDenegado = ()=>{
      setAviso({
         Show:true,
         Logo:'BsFillXCircleFill',
         Color:'red-500',
         Texto:"Acceso Denegado",
         Login:'',
         AlignTexto:true,
         Botones:{
            Bcerrar:true,
         }
      })
   }

   useEffect(async() => {
      loggedInCheck();
      setShowSpinner(true)
      const arrayidCliente = JSON.parse(user.id_cliente);
      const array_cliente = [];

      try{
         const {data} = await Axios.post('/cliente/consultageneral.php',{});
         if(arrayidCliente.length > 0){
            arrayidCliente.forEach(elementA => {
               data.datos.forEach(elementB => {
                  if(parseInt(elementA)==parseInt(elementB.id_cliente)){
                     array_cliente.push({
                         id_cliente:parseInt(elementB.id_cliente),
                         nombre_cliente:elementB.nombre_cliente
                     });
                  }
               });
            });
         }else{
            data.datos.forEach(elementB => {
               array_cliente.push({
                  id_cliente:parseInt(elementB.id_cliente),
                  nombre_cliente:elementB.nombre_cliente
               });
            });
         }
         setArrayCliente(array_cliente)
         setShowSpinner(false)
      } catch(err){ console.log("err");console.log(err) }
   }, [])
   
   return(
      <Suspense fallback={<Spinner />}>
         <>
            {ShowSpinner && <Spinner/>}
            {user.status_usuario ?
               <>
                  {Aviso.Show &&
                     <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                           <div className="relative w-auto my-6 mx-auto max-w-sm">
                              {/*content*/}
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                                 {/*header*/}
                                 <div className="flex justify-center items-center rounded-t">
                                    {Aviso.Logo=="BsFillQuestionCircleFill" && <span className="p-2"><BsFillQuestionCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                                    {Aviso.Logo=="BsFillCheckCircleFill" && <span className="p-2"><BsFillCheckCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}    
                                    {Aviso.Logo=="BsFillXCircleFill" && <span className="p-2"><BsFillXCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                                 </div>
                                 {/*body*/}
                                 <div className="relative p-4 flex-auto text-white">
                                    <p className={`${(Aviso.AlignTexto ? "text-center" : "text-justify")} my-1 font-bold whitespace-pre-line`}>
                                       {Aviso.Texto}
                                    </p>
                                 </div>
                                 {/*footer*/}
                                 <div className="flex items-center justify-center rounded-b px-2">
                                    <div className="flex items-center justify-between">
                                       {Aviso.Botones.Bcerrar && <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={()=>CerrarAviso(Aviso.Login)}>Cerrar</button>}
                                       {Aviso.Botones.Benviar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>}
                                       {Aviso.Botones.Beliminar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
                     </>
                  }
                  {/* ############################### BODY ################################### */}
                  <div className={` ${LocacionHttp[3]!="home" && "w-screen"}`}>
                     <fieldset className={` ${LocacionHttp[3]!="home" ? "border-double border-4 border-sky-900 rounded-lg p-3 m-2 mb-10" : ""}`}>
                        <legend className={` ${LocacionHttp[3]!="home" ? "py-2 px-2 bg-sky-900 rounded-lg text-lg font-bold text-white" : "hidden"}`}><span className="mr-1">Personal</span></legend>
                        
                           <fieldset className="border-solid border-2 border-sky-900 rounded-lg p-1 m-0.5 mb-2">
                              {(user.V_T || user.F_2) ?
                                 <legend className="px-1 py-0.5 bg-sky-900 rounded-md text-xs  font-bold text-white hover:bg-sky-700">
                                       <button onClick={()=> {OpenDivFuncion("personal_agregaractualizarpersonal"); setDataPersonalExiste({Show: false}); setDataPersonalAgregar({Show: false}); setDataPersonalActualizar({Show: false})}}><div className="flex flex-row items-center"><span className="mr-1">Agregar / Actualizar</span><span className="font-bold text-md">{OpenFuncion=="personal_agregaractualizarpersonal" ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill/>}</span></div></button>
                                 </legend>
                              :
                                 <legend className="px-1 py-0.5 bg-red-500 rounded-md text-xs  font-bold text-white hover:bg-red-500">
                                       <button onClick={()=> { AccesoDenegado(); }}><div className="flex flex-row items-center"><span className="mr-1">Agregar / Actualizar</span><span className="font-bold text-md">{OpenFuncion=="personal_agregaractualizarpersonal" ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill/>}</span></div></button>
                                 </legend>
                              }
                              {OpenFuncion=="personal_agregaractualizarpersonal" &&
                                 <>
                                    {(user.V_T || user.F_2) &&
                                       <>
                                          {/* div busqueda */}
                                          <Formik
                                             initialValues={{
                                                cedula:'',
                                             }}
                                             validate={(valores) =>{
                                                let errores={}
                                                
                                                if(!valores.cedula){
                                                   errores.cedula='Por favor, ingrese un número'
                                                }else if (!Number.isInteger(parseFloat(valores.cedula))){
                                                   errores.cedula='Por favor, ingrese un número entero'
                                                }else if (parseFloat(valores.cedula) <= 3000000 && valores.cedula >= 40000000) {
                                                   errores.cedula='Por favor, ingrese un número válido'
                                                }
                                                return errores
                                             }}
                                       >
                                             {({values, errors, touched, handleSubmit, handleChange, handleBlur}) =>(
                                                <form onSubmit={handleSubmit}>

                                                   <div className="flex flex-col items-center">

                                                      <div className="flex flex-row m-4">
                                                         <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                                            <label htmlFor="cedula" className="font-bold text-white mb-2">Nro. Cédula</label>
                                                            <input 
                                                               type="number"
                                                               min={3000000} 
                                                               max={40000000}
                                                               step="1"
                                                               id="cedula"
                                                               name="cedula"
                                                               placeholder="########"
                                                               className="form-select block w-full px-3 pl-2 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none numerico" 
                                                               value={values.cedula}
                                                               onChange={event=>{ handleChange(event); setDataPersonalAgregar({Show: false}); setDataPersonalActualizar({Show: false}); setDataPersonalExiste({Show: false})}}
                                                               onBlur={event =>{handleBlur(event); ChangeCedula(event) }}
                                                            />
                                                            {touched.cedula && errors.cedula && <div className="text-xs  font-semibold text-white bg-red-500 italic px-2">{errors.cedula}</div>}
                                                         </div>
                                                      </div>

                                                   </div>

                                                </form>
                                             )}
                                          </Formik>
                                          {/* fin div busqueda */}

                                          {/* presonal existe */}
                                          {DataPersonalExiste.Show &&
                                             <PersonalExiste datos={DataPersonalExiste}/>
                                          }
                                          {/* fin presonal existe */}

                                          {/* formulario agregar */}
                                          {DataPersonalAgregar.Show &&
                                             <AgregarDatos datos={DataPersonalAgregar}/>
                                          }
                                          {/* FIN FORMULARIO AGREGAR */}
                                          
                                          {/* formulario actualizar */}
                                          {DataPersonalActualizar.Show &&
                                             <ActualizarDatos datos={DataPersonalActualizar}/>
                                          }
                                          {/* fin formulario actualizar */}
                                       </>
                                    }
                                 </>
                              }
                           </fieldset>

                           <fieldset className="border-solid border-2 border-sky-900 rounded-lg p-1 m-0.5 mb-2">
                              {(user.V_T || user.F_3) ?
                                 <legend className="px-1 py-0.5 bg-sky-900 rounded-md text-xs  font-bold text-white hover:bg-sky-700">
                                    <button onClick={()=> { OpenDivFuncion("personal_consultarpersonal"); setDataPersonalExiste({Show: false}); setDataPersonalAgregar({Show: false}); setDataPersonalActualizar({Show: false}); ConsultarPersonal() }}><div className="flex flex-row items-center"><span className="mr-1">Consultar</span><span className="font-bold text-md">{OpenFuncion=="personal_consultarpersonal" ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill/>}</span></div></button>
                                 </legend>
                              :
                                 <legend className="px-1 py-0.5 bg-red-500 rounded-md text-xs  font-bold text-white hover:bg-red-500">
                                    <button onClick={()=> { AccesoDenegado(); }}><div className="flex flex-row items-center"><span className="mr-1">Consultar</span><span className="font-bold text-md">{OpenFuncion=="personal_consultarpersonal" ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill/>}</span></div></button>
                                 </legend>
                              }
                              {OpenFuncion=="personal_consultarpersonal" &&
                                 <>
                                    {DataPersonalConsulta.Show &&
                                       <PersonalConsulta datos={DataPersonalConsulta}/>
                                    }
                                    {/* formulario actualizar */}
                                    {DataPersonalActualizar.Show &&
                                       <fieldset className={` ${DataPersonalActualizar.ShowFieldeset ? "border-solid border-2 border-sky-900 rounded-lg p-3 m-2 mb-4" : ""}`}>
                                          <legend className={` ${DataPersonalActualizar.ShowFieldeset ? "py-0.5 px-2 bg-sky-900 rounded-lg text-lg font-bold text-white hover:bg-red-500" : "hidden"}`}> <button type="button" onClick={()=>{setDataPersonalActualizar({Show:false}); ConsultarPersonal()}}>X</button> </legend>
                                             <ActualizarDatos datos={DataPersonalActualizar} ShowFieldeset={true}/>
                                       </fieldset>
                                    }
                                    {/* fin formulario actualizar */}
                                 </>
                              }
                           </fieldset>

                     </fieldset>
                  </div>
                  {/* ############################### FIN BODY ################################### */}
               </>
            :
               <Nousuario user={user.email_usuario}/>
            }
         </>
      </Suspense>
   )
}

export default AgregarPersonal