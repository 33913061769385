import {createContext, useState, useEffect} from 'react'
import axios from 'axios'
import Cookies from 'js-cookie';
import isEqual from 'lodash/isEqual';

export const UserContext = createContext();

export const Axios = axios.create({
    /* baseURL: 'http://localhost/api/', */
    baseURL: 'https://sercosys.sercoinfal.net/api/',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

export const UserContextProvider = ({children}) => {

    const [theUser, setUser] = useState(null);
    const [theAsignacion, setAsignacion] = useState(null);
    const [wait, setWait] = useState(false);
    const [Timestamp, setTimestamp] = useState({});

    const loginUser = async ({email,password}) => {
        setWait(true);
        try{
            const {data} = await Axios.post('login.php',{
                email, password
            });
            /* console.log('data-login', data) */
            if(data.success && data.token){
                //localStorage.setItem('loginToken', data.token);
                Cookies.set('loginToken', data.token, { expires: 7, secure: true, sameSite: 'Strict' });
                setWait(false);
                return {success:1};
            }
            setWait(false);
            return {success:0, message:data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'¡Error del servidor!'};
        }

    }

    const loggedInCheck = async () => {
        /* const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = loginToken; */
        const loginToken = Cookies.get('loginToken');
        Axios.defaults.headers.common['Authorization'] = loginToken;
        if(loginToken){
            const {data} = await Axios.get('getUser.php');
            /* console.log('data-logintoken', data) */
            if(data.success && data.user){
                setUser(data.user);
                setAsignacion(data.asignacion);
                setTimestamp({
                    DMY:data.DMY,
                    HH:data.HH
                })
                return {user_activo:data.user};
            }
            setUser(null);
            return {user_activo:""};
        }
    }

    const logout = async({email}) => {
        localStorage.removeItem('loginToken');
        try{
            const {data} = await Axios.post('logout.php',{
                email,
            });
            if(data.success){
                setUser(null);
            }
        }
        catch(err){ return {success:0, message:'Server Error!'}; }
    }

    useEffect(() => {
        async function asyncCall(){
            await loggedInCheck();
        }
        asyncCall();
    },[]);

    return (
        <UserContext.Provider value={{loginUser,wait, user:theUser,asignacion:theAsignacion,loggedInCheck,logout, Timestamp}}>
            {children}
        </UserContext.Provider>
    );

}

export default UserContextProvider;