import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import LogoA from '../../resourse/img/logoA.png'

const Nousuario = (props) => {
   const {user, logout} = useContext(UserContext)
   const CerrarSesion = async(email) => {
      const data = await logout({
          email
      });
   }

   return (
      <>
         <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
               {/*content*/}
               <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                  {/*header*/}
                  <div className="flex justify-center items-center rounded-t p-2">
                     <img id="avatarButton" type="button" className="h-20 bg-white p-2 rounded" src={LogoA} />
                  </div>
                  {/*body*/}
                  <div className="relative p-2 flex justify-center items-center text-white">
                        <span className="my-1 text-center text-md"><b>¡Lo sentimos!</b><br/>El Usuario actual está<br/><b>INACTIVO</b></span>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-around rounded-b px-2">
                     <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 transition-all ease-in duration-150" type="button" onClick={() => CerrarSesion(user.email_usuario)}>Refrescar Página</button>
                  </div>
               </div>
            </div>
         </div>
         <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
      </>
   );
 }
 
 export default Nousuario

