import { BsFillXCircleFill} from "react-icons/bs"

function Error404(props) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
         <div className="relative w-auto my-6 mx-auto max-w-sm">
         {/*content*/}
         <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
            {/*header*/}
            <div className="flex justify-center items-center rounded-t">
               <span className="p-2"><BsFillXCircleFill className="text-red-500 text-5xl"/></span>
            </div>
            {/*body*/}
            <div className="relative p-4 flex-auto text-white">
               <p className="text-center my-1 font-bold">Página NO existe</p>
               <p className="text-center my-1 font-bold">Contacte al administrador</p>
            </div>
            {/*footer*/}
            {/* <div className="flex items-center justify-center rounded-b px-2">
               <div className="w-full flex items-center justify-around bg-black">
                  <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border-2 text-xl" type="button">Cerrar</button>
                  <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border-2 text-xl" type="button">Si</button>
               </div>
            </div> */}
         </div>
         </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
   </>
  )
}

export default Error404