import {Navigate} from 'react-router-dom';
import {useContext} from 'react'
import {UserContext} from '../context/UserContext';

export const RedirectRoute = ({ children, component: Component, redirectTo, path, ...props }) => {
    const {user} = useContext(UserContext);
    return (
        <>
            {user ? 
                <Navigate to="/home" /> 
            : 
                children 
            }
        </>
    )
}