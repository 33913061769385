import { useContext, useState, useEffect, Suspense } from 'react'
import { UserContext } from '../../../context/UserContext'
import { BsFillTrashFill, BsFillQuestionCircleFill, BsFillCheckCircleFill, BsFillXCircleFill} from "react-icons/bs"
import {BiLoaderAlt} from "react-icons/bi"
import { Formik } from "formik"
import Spinner from "../../modal/Spinner";
import Nousuario from "../../modal/NoUsuario";
import { SeparateDateSelect } from "../../funciones/WorkDate"
import { Axios } from '../../../context/UserContext';

const RelacionServicioComida = (props) => {
   const {user, asignacion, loggedInCheck} = useContext(UserContext)
   const [LocacionHttp, setLocacionHttp] = useState(window.location.href.split("/"));
   const [ShowSpinner, setShowSpinner] = useState(false)
   const [ShowMensaje, setShowMensaje] = useState(false)
   const [ArrayCliente, setArrayCliente] = useState(asignacion.clientes)
   const [ArrayPersonal, setArrayPersonal] = useState({})
   const [ArraySucursal, setArraySucursal] = useState({})
   const [AsigSucursal, setAsigSucursal] = useState(asignacion.sucursales)
   const [ArrayServicio, setArrayServicio] = useState({})
   const [AsigServicio, setAsigServicio] = useState(asignacion.servicios)
   const [AsigGerencia, setAsigGerencia] = useState({})
   const [ArraySolicitudServicio, setArraySolicitudServicio] = useState({})
   const [ArrayCedulaSolicitud, setArrayCedulaSolicitud] = useState({})
   const [ArrayDataGerenciaSolicitud, setArrayDataGerenciaSolicitud] = useState({})
   const [ArrayDataTGerenciaSolicitud, setArrayDataTGerenciaSolicitud] = useState({})
   const [ShowInputCedula, setShowInputCedula] = useState(false)
   const [OpenFuncion, setOpenFuncion] = useState("")
   const OpenDivFuncion = (value) =>{setOpenFuncion(OpenFuncion == value ? "" : value)}
   const [ArrayDCS, setArrayDCS] = useState({})
   const [Aviso, setAviso] = useState({Show:false})
   const [TableCoincidencia, setTableCoincidencia] = useState({
      Show:false,
      Valor:[]
   })
   const [progress, setProgress] = useState(0);
   const AccesoDenegado = ()=>{
      setAviso({
         Show:true,
         Logo:'BsFillXCircleFill',
         Color:'red-500',
         Texto:"Acceso Denegado",
         Login:'',
         AlignTexto:true,
         Botones:{
            Bcerrar:true,
         }
      })
   }
   const CerrarAviso = (login) => {
      setAviso({Show:false})
   }
   const BuscarCoincidencia = (e)=>{
      const fecha = document.getElementById('fecha').value;
      const fechasel= SeparateDateSelect(fecha)
      const id_cliente = document.getElementById('cliente').value;
      const id_sucursal = document.getElementById('sucursal').value;
      const id_servicio = document.getElementById('servicio').value;
      const tipo_peticion = 1
      
      setArrayDCS({})
      if(fecha!="" && id_cliente!="" && id_sucursal!="" && id_servicio!=""){
         setShowSpinner(true)
         async function main() {
            try{
               const {data} = await Axios.post('/solicitudcomensal.php',{
                  fechasel, id_cliente, id_sucursal, id_servicio, tipo_peticion, AsigGerencia
               });
               /* console.log("data-solicitud", data) */
               if(data.success){
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

                  const array_servicio = data.datos;
                  const array_cedula_servicio = [];
                  const array_gerencia = [];
                  const array_data_gerencia = [];
                  const array_data_Tgerencia = [];

                  
                  array_servicio.sort(function (a, b) {
                     if (a.id_gerencia > b.id_gerencia) {return 1;}
                     if (a.id_gerencia < b.id_gerencia) {return -1;}
                     return 0;
                  });
                  setArraySolicitudServicio(array_servicio)
                  
                  data.datos.forEach(element => {
                     array_cedula_servicio.push(element.nrocedula_personal)
                     array_gerencia.push(element.id_gerencia)
                  });
                  let result_array_cedula_servicio = array_cedula_servicio.filter((item,index)=>{
                     return array_cedula_servicio.indexOf(item) == index;
                  })
                  let result_array_gerencia = array_gerencia.filter((item,index)=>{
                     return array_gerencia.indexOf(item) == index;
                  })
                  setArrayCedulaSolicitud(result_array_cedula_servicio)
                  

                  let Ttotal=0
                  let Tnormal=0
                  let Tdieta=0
                  let Tespecial=0
                  result_array_gerencia.forEach(ge => {
                     let sum=0
                     let normal=0
                     let dieta=0
                     let especial=0
                     let nombre_ge=''
                     array_servicio.forEach(so => {
                           if(ge==so.id_gerencia){
                              Ttotal++
                              sum++
                              nombre_ge=so.nombre_gerencia
                              switch (so.id_tipocomensal) {
                                 case 1:
                                       normal++
                                       Tnormal++
                                       break;
                                 case 2:
                                       dieta++
                                       Tdieta++
                                       break;
                                 case 3:
                                       especial++
                                       Tespecial++
                                       break;
                              }
                           }
                     });

                     array_data_gerencia.push({
                           gerencia:nombre_ge,
                           normal:normal,
                           dieta:dieta,
                           especial:especial,
                           cantd:sum,
                     })
                  });
                  array_data_Tgerencia.push({
                     Tgerencia:"TOTAL",
                     Tnormal:Tnormal,
                     Tdieta:Tdieta,
                     Tespecial:Tespecial,
                     Tcantd:Ttotal
                  })
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
                  const [ySel, mSel, dSel] = fecha.split("-");
                  const [fechaParte, horaParte] = data.FechaAhora.split(" ");
                  const [ySis, mSis, dSis] = fechaParte.split("-");
                  const [hSis, iSis, sSis] = horaParte.split(":");                  
                  let nombre_cliente = ''
                  let nombre_sucursal = ''
                  let nombre_servicio = ''
                  ArrayCliente.some(element => {
                     if(element.id_cliente==id_cliente){
                        nombre_cliente=element.nombre_cliente
                        return true
                     }
                  });
                  ArraySucursal.Datos.some(element => {
                     if(element.id_sucursal==id_sucursal){
                        nombre_sucursal=element.nombre_sucursal
                        return true
                     }
                  });
                  ArrayServicio.Datos.some(element => {
                     if(element.id_servicio==id_servicio){
                        nombre_servicio=element.nombre_servicio
                        return true
                     }
                  });
                  
                  setArrayDataGerenciaSolicitud(array_data_gerencia)
                  if(array_data_gerencia.length >1){
                     setArrayDataTGerenciaSolicitud(array_data_Tgerencia)
                  }else{
                     setArrayDataTGerenciaSolicitud({})
                  }
                  setTableCoincidencia({
                     Show:true,
                     fecha_solicitud:dSel+'/'+mSel+'/'+ySel,
                     fecha_sistema:dSis+'/'+mSis+'/'+ySis,
                     hora_sistema:hSis+':'+iSis+':'+sSis,
                     nombre_cliente: nombre_cliente,
                     nombre_sucursal: nombre_sucursal,
                     nombre_servicio: nombre_servicio,
                  })
               }
            } catch(err){ console.log("err");console.log(err) } finally {setShowSpinner(false);}
         }
         main();
      }
   }
   const ChangeFecha = (e)=>{
      setArraySucursal({ Show:false,Datos:'' })
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false})
      setShowMensaje({Show:false})
   }
   const ChangeCliente = async(e)=>{
      setArraySucursal({ Show:false,Datos:'' })
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false})
      setShowMensaje({Show:false})
      const id_cliente = document.getElementById('cliente').value;
      if(id_cliente!=''){
         setShowSpinner(true)
         async function task1() {
            try{
               const {data} = await Axios.post('/sucursal_consultaporcliente.php',{
                  id_cliente, AsigSucursal
               });
               if(data.success==1){
                  setArraySucursal({
                     Show:true,
                     Datos:data.datos
                  })
               }
            } catch(err){ console.log("err");console.log(err) }
         }
         async function task2() {
            try{
               const {data} = await Axios.post('/personal_consultaporcliente.php',{
                  id_cliente, AsigGerencia
               });
               /* console.log("data-personal")
               console.log(data) */
               if(data.success==1){
                  data.datos.sort((a, b) => {
                     let comparison = a.nombres_personal.localeCompare(b.nombres_personal);
                     if (comparison == 0) {
                         return a.apellidos_personal.localeCompare(b.apellidos_personal);
                     }
                     return comparison;
                 });
                  setArrayPersonal(data.datos)
               }
               
            } catch(err){ console.log("err");console.log(err) }
         }
         async function main() {
            await Promise.all([task1(), task2()]);
            setShowSpinner(false)
         }
         main()
      }
   }   
   const ChangeSucursal = async(e)=>{
      setShowSpinner(true)
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false,})
      setShowMensaje({Show:false})
      const id_sucursal = document.getElementById('sucursal').value;

      try{
         const {data} = await Axios.post('/servicio_consultaporsucursal.php',{
            id_sucursal, AsigServicio
         });
         /* console.log("data-servicio")
         console.log(data) */
         if(data.success==1){
            setArrayServicio({
               Show:true,
               Datos:data.datos
            })
         }
      } catch(err){ console.log("err");console.log(err) }finally {setShowSpinner(false);}
   }
   const ChangeServicio = async(e)=>{
      
      setTableCoincidencia({Show:false})
      setShowMensaje({Show:false})
      const fecha = document.getElementById('fecha').value;
      const id_servicio = document.getElementById('servicio').value;

      if(fecha!="" && id_servicio!=""){
         setShowSpinner(true)
         const [ySel, mSel, dSel]=fecha.split('-')
         const fechaSel=new Date(ySel,mSel-1,dSel)
         let HoToSolicitud=""
         let HoFin=""
         let Sumar=5
         let Restar=0
         try{
            const {data} = await Axios.post('/consultaGrupoFechaHora.php',{
               id_servicio
            });
            /* console.log("data- fecha/hora", data)*/
            const [ySis, mSis, dSis]=data.fecha.split('-')
            const [hSis, iSis]=data.hora.split(':')
            const fechaSis=new Date(ySis,mSis-1,dSis)

            ArrayServicio.Datos.some(element => {
                if(element.id_servicio==id_servicio){
                    HoToSolicitud=element.HoToSolicitud
                    HoFin=element.HoFin
                    if(element.DiaAnterior){
                       Restar=1
                    }
                    return true
                }
            });            

            if(fechaSel.getTime() < fechaSis.getTime()){
               setShowInputCedula(false)
            }else if(fechaSel.getTime() > fechaSis.getTime()){
                    const [hSel, iSel, sSel]=HoToSolicitud.split(':')
                    let fechaSel2=new Date(ySel,mSel-1,dSel,hSel,iSel)
                    fechaSel2.setDate(fechaSel2.getDate() - Restar);
                    fechaSel2.setMinutes(fechaSel2.getMinutes() + Sumar);
                    const dR = fechaSel2.getDate();
                    const mR = fechaSel2.getMonth() + 1;
                    const yR = fechaSel2.getFullYear();
                    const HR = fechaSel2.getHours();
                    const iR = fechaSel2.getMinutes();
                    setShowMensaje({
                        Show: true,
                        Msg: 'Solicitud disponible hasta el ('+dR+'/'+mR+'/'+yR+' ' + hSel + ':' + iSel + ')'
                    })
            }else{
                console.log('igual')

                const [hSel, iSel, sSel] = (Restar > 0 ? HoFin : HoToSolicitud).split(':');
                let fechaSis2=new Date(ySis,mSis-1,dSis,hSis,iSis)
                /* console.log("fechaSis2")
                console.log(fechaSis2) */
                let fechaSel2=new Date(ySel,mSel-1,dSel,hSel,iSel)
                /* console.log("fechaSel2- antes")
                console.log(fechaSel2) */
                fechaSel2.setMinutes(fechaSel2.getMinutes() + Sumar);
                /* console.log("fechaSel2- despues")
                console.log(fechaSel2) */
                // Extraer día, mes y año
                const dR = (fechaSel2.getDate()<=9 ? '0'+fechaSel2.getDate() :fechaSel2.getDate())
                const mR = (fechaSel2.getMonth()<=9 ? '0'+fechaSel2.getMonth() :fechaSel2.getMonth())
                const yR = fechaSel2.getFullYear();
                const hR = (fechaSel2.getHours()<=9 ? '0'+fechaSel2.getHours() :fechaSel2.getHours())
                const iR = (fechaSel2.getMinutes()<=9 ? '0'+fechaSel2.getMinutes() :fechaSel2.getMinutes())

                if(fechaSis2.getTime() > fechaSel2.getTime()){
                    setShowInputCedula(true)
                }else if(fechaSis2.getTime() < fechaSel2.getTime()){
                    setShowInputCedula(false)
                    /* setShowMensaje({
                        Show: true,
                        Msg: 'La solicitud está disponible. Consulte a partir de las (' + hR + ':' + iR + ')'
                    }); */
                    setShowMensaje({
                     Show: true,
                     Msg: Restar == 0 && 'La solicitud está disponible. Consulte a partir de las (' + hR + ':' + iR + ')'
                 })
                }
            }
            
         } catch(err){ console.log("err");console.log(err) }finally {setShowSpinner(false); BuscarCoincidencia()}
      }

   }
   
   return(
      <Suspense fallback={<Spinner />}>
         <>
            {ShowSpinner && <Spinner/>}
            {progress > 0  &&
               <>
                     <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-sm">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                           {/*header*/}
                           <div className="flex justify-center items-center rounded-t"></div>
                           {/*body*/}
                           <div className="w-full p-4 flex flex-col justify-center items-center text-white">
                                 <BiLoaderAlt className="animate-spin"/>
                                 <label htmlFor="" className="text-white mb-1">Cargando...</label>
                                 <div className="relative pt-1 w-full">
                                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white">
                                       <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-sky-400"></div>
                                    </div>
                                 </div>
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-center rounded-b px-2"></div>
                        </div>
                     </div>
               </div>
               <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
               </>
            }
            {Aviso.Show &&
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-sm">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                           {/*header*/}
                           <div className="flex justify-center items-center rounded-t">
                              {Aviso.Logo=="BsFillQuestionCircleFill" && <span className="p-2"><BsFillQuestionCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillCheckCircleFill" && <span className="p-2"><BsFillCheckCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}    
                              {Aviso.Logo=="BsFillXCircleFill" && <span className="p-2"><BsFillXCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                           </div>
                           {/*body*/}
                           <div className="relative p-4 flex-auto text-white">
                              {Aviso.Texto &&
                                 <p className={`${(Aviso.AlignTexto ? "text-center" : "text-justify")} my-1 font-bold`}>
                                       <pre> {Aviso.Texto} </pre>
                                 </p>
                              }
                              {Aviso.TextoAgregada!='' &&
                                 <div className='flex justify-around items-center text-xs'>
                                    <div className='mr-4'><span className="p-2"><BsFillCheckCircleFill className="text-green-700 text-3xl"/></span></div>
                                    <div className='overflow-y-scroll max-h-40 mb-1'>
                                       <p className="text-justify mr-4">
                                          <pre className='mr-6'>{Aviso.TextoAgregada}</pre>
                                       </p>
                                    </div>
                                 </div>
                              }
                              {Aviso.TextoDuplicada!='' &&
                                 <>
                                    {Aviso.TextoAgregada!='' &&<hr className='m-2'/>}
                                    <div className='flex justify-around items-center text-xs'>
                                       <div className='mr-4'><span className="p-2"><BsFillXCircleFill className="text-red-700 text-3xl"/></span></div>
                                       <div className='overflow-y-scroll max-h-40 mb-1'>
                                          <p className="text-justify mr-4">
                                             <pre className='mr-6'>{Aviso.TextoDuplicada}</pre>
                                          </p>
                                       </div>
                                    </div>
                                 </>
                              }
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-center rounded-b px-2">
                              <div className="flex items-center justify-between">
                                 {Aviso.Botones.Bcerrar && <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={()=>CerrarAviso(Aviso.Login)}>Cerrar</button>}
                                 {Aviso.Botones.Benviar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>}
                                 {Aviso.Botones.Beliminar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
               </>
            }

            {user.status_usuario ?
               <>
                  {/* ############################### BODY ################################### */}
                  <div className={` ${LocacionHttp[3]!="home" && "w-screen"}`}>
                     <fieldset className={` ${LocacionHttp[3]!="home" ? "border-double border-4 border-sky-900 rounded-lg p-3 m-2 mb-10" : ""}`}>
                        <legend className={` ${LocacionHttp[3]!="home" ? "py-2 px-2 bg-sky-900 rounded-lg text-lg font-bold text-white" : "hidden"}`}><span className="mr-1">Personal</span></legend>

                        <Formik
                            initialValues={{
                                fecha:'',
                                cliente:'',
                                sucursal:'',
                                servicio:'',
                                buscar:'',
                                cedula:[]
                            }}
                            validate={(valores) =>{
                                
                                let errores={}
                                if(!valores.fecha){
                                    errores.fecha='Por favor, seleccione una fecha'
                                }
                                if(!valores.cliente){
                                    errores.cliente='Por favor, seleccione un item'
                                }
                                if(!valores.sucursal){
                                    errores.sucursal='Por favor, seleccione un item'
                                }
                                if(!valores.servicio){
                                    errores.servicio='Por favor, seleccione un item'
                                }
                                return errores
                            }}
                            onSubmit = {async (valores,{resetForm}) => {
                                let arr_cedula = valores.cedula;
                            
                                const fechasel = SeparateDateSelect(valores.fecha);
                                const id_cliente = valores.cliente;
                                const id_sucursal = valores.sucursal;
                                const id_servicio = valores.servicio;
                                const id_usuario = user.id_usuario
                                const tipo_peticion = 2;
                            
                                const config = {
                                    onUploadProgress: (progressEvent) => {
                                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                        setProgress(percentCompleted);
                                    }
                                };
                            
                                try {
                                    const { data } = await Axios.post('/solicitudcomensal.php', {
                                    id_usuario, fechasel, id_cliente, id_sucursal, id_servicio, arr_cedula, tipo_peticion, AsigGerencia
                                    }, config);
                            
                                    /* console.log("data-insertar", data); */
                            
                                    if (data.success == 1) {
                                    let texto_agregada=""
                                    let texto_duplicada=""
                                    
                                    if(data.arr_agregadas.length >0){
                                        let num=''
                                        if(data.arr_agregadas.length <= 9){
                                            num='0'+data.arr_agregadas.length
                                        }else{                                             
                                            num=data.arr_agregadas.length
                                        }
                                        texto_agregada='Solicitud Registrada: ('+num+')\n'+data.arr_agregadas.map(obj =>`- ${obj.nombres} ${obj.apellidos}\n\tC.I.: ${new Intl.NumberFormat('es-ES').format(obj.cedula)}`).join('\n');
                                    }

                                    if(data.arr_duplicadas.length >0){
                                        let num=''
                                        if(data.arr_duplicadas.length <= 9){
                                            num='0'+data.arr_agregadas.length
                                        }else{
                                            num=data.arr_agregadas.length
                                        }
                                        texto_duplicada='Solicitud Duplicada: (0'+data.arr_duplicadas.length+')\n'+data.arr_duplicadas.map(obj =>`- ${obj.nombres} ${obj.apellidos}\n\tC.I.: ${obj.cedula}`).join('\n');
                                    }
                                    setAviso({
                                        Show:true,
                                        TextoAgregada:texto_agregada,
                                        TextoDuplicada:texto_duplicada,
                                        AlignTexto:true,
                                        Botones:{
                                            Bcerrar:true,
                                        }
                                    })
                                    setTableCoincidencia({Show:false,})
                                    resetForm({ 
                                        values: { 
                                            fecha:valores.fecha,
                                            cliente:valores.cliente,
                                            sucursal:valores.sucursal,
                                            servicio:valores.servicio,
                                        },
                                    });
                                    }
                                    setProgress(0)
                                } catch(err) {
                                    console.error("Error during submission:", err);
                                    setProgress(0)
                                } finally {
                                    setProgress(0)
                                    ChangeServicio()
                                }
                            }}
                        >
                           {({values, errors, touched, handleSubmit, handleChange, handleBlur}) =>(
                              <form onSubmit={handleSubmit}>
                                 <div className="flex justify-between items-center m-4">
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="fecha" className="font-bold text-white mb-2">Fecha</label>
                                       <input 
                                             type="date" 
                                             id="fecha" 
                                             name="fecha"
                                             value={values.fecha}
                                             className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                             onChange={event=>{ handleChange(event); ChangeFecha(event); }}
                                             onClick={(event) => {
                                                values.cliente=""
                                                values.sucursal=""
                                                values.servicio=""
                                             }}
                                             onBlur={event =>{handleBlur(event); }}
                                       />
                                       {touched.fecha && errors.fecha && <div className="text-sm font-semibold text-red-500 italic">{errors.fecha}</div>}
                                    </div>
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                          <label htmlFor="cliente" className="font-bold text-white mb-2">Cliente</label>
                                          <select 
                                             id="cliente" 
                                             name="cliente"
                                             value={values.cliente}
                                             className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                             onChange={event=>{ handleChange(event); ChangeCliente(event); }}
                                             onClick={(event) => {
                                                values.sucursal=""
                                                values.servicio=""
                                             }}
                                             onBlur={handleBlur}
                                          >
                                             <option value="">---Selección---</option>
                                             {ArrayCliente.length>0 && (
                                                ArrayCliente.map(function(d,i) {
                                                      return (
                                                         <option key={"cliente_"+i} value={d.id_cliente}>{d.nombre_cliente}</option>
                                                      )
                                                })
                                             )}
                                          </select>
                                          {touched.cliente && errors.cliente && <div className="text-sm font-semibold text-red-500 italic">{errors.cliente}</div>}
                                    </div>
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="sucursal" className="font-bold text-white mb-2">Sucursal</label>
                                       <select 
                                             id="sucursal" 
                                             name="sucursal"
                                             value={values.sucursal}
                                             className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                             onChange={event=>{ handleChange(event); ChangeSucursal(event); }}
                                             onClick={(event) => {
                                                values.servicio=""
                                             }}
                                             onBlur={handleBlur}
                                       >
                                             <option value="">---Selección---</option>
                                             {ArraySucursal.Show && (
                                                ArraySucursal.Datos.map(function(d,i) {
                                                   return (
                                                      <option key={"sucursal_"+i} value={d.id_sucursal}>{d.nombre_sucursal}</option>
                                                   )
                                                })
                                             )}
                                       </select>
                                       {touched.sucursal && errors.sucursal && <div className="text-sm font-semibold text-red-500 italic">{errors.sucursal}</div>}
                                    </div>
                                    <div className="w-auto m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="servicio" className="font-bold text-white mb-2">Servicio</label>
                                       <select 
                                             id="servicio" 
                                             name="servicio"
                                             value={values.servicio}
                                             onChange={event=>{ handleChange(event); ChangeServicio(event);}}
                                             onBlur={handleBlur}
                                             className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                       >
                                             <option value="">---Selección---</option>
                                             {ArrayServicio.Show && (
                                                ArrayServicio.Datos.map(function(d,i) {
                                                   return (
                                                      <option key={"servicio_"+i} value={d.id_servicio}>{d.nombre_servicio}</option>
                                                   )
                                                })
                                             )}
                                       </select>
                                       {touched.servicio && errors.servicio && <div className="text-sm font-semibold text-red-500 italic">{errors.servicio}</div>}
                                    </div>
                                 </div>
                                 {ShowMensaje.Show &&
                                     <>
                                        <div className='flex justify-center items-center font-bold text-red-500'>{ShowMensaje.Msg}</div>
                                     </>
                                 }
                                 {(TableCoincidencia.Show) &&
                                    <>
                                        <div className="w-100 overflow-x-auto">
                                            <div id="div_pdf" className="p-1.5 w-full inline-block align-middle">
                                                <div className="p-1.5 w-full flex justify-center items-center">
                                                   <table className="min-w-auto divide-y divide-gray-200">
                                                      <thead className="bg-sky-900">
                                                         <tr>
                                                            <th scope="col" colSpan={5} className="px-2 py-1 text-xs border border-slate-300 text-center text-white">
                                                                  <div className="flex flex-col items-center justify-center">
                                                                     <div className='flex justify-around items-center mb-2'>
                                                                        <div className='flex flex-col mx-2'>
                                                                           <span className='text-sm font-black underline'>RELACIÓN SOLICITUD</span>
                                                                           <span>{TableCoincidencia.fecha_solicitud}</span>
                                                                        </div>
                                                                        <div className='flex flex-col mx-2'>
                                                                           <span className='text-sm font-black underline'>CLIENTE</span>
                                                                           <span>{TableCoincidencia.nombre_cliente}</span>
                                                                        </div>
                                                                        <div className='flex flex-col mx-2'>
                                                                           <span className='text-sm font-black underline'>SUCURSAL</span>
                                                                           <span>{TableCoincidencia.nombre_sucursal}</span>
                                                                        </div>
                                                                        <div className='flex flex-col mx-2'>
                                                                           <span className='text-sm font-black underline'>SERVICIO</span>
                                                                           <span>{TableCoincidencia.nombre_servicio}</span>
                                                                        </div>
                                                                     </div>
                                                                     <div>EDICIÓN: {TableCoincidencia.fecha_sistema} {TableCoincidencia.hora_sistema}</div>
                                                                  </div>
                                                            </th>
                                                         </tr>
                                                         <tr>
                                                            <th width="auto%" scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">UNIDAD</th>
                                                            <th width="10%" scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">NORMAL</th>
                                                            <th width="10%" scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">DIETA</th>
                                                            <th width="10%" scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">ESPECIAL</th>
                                                            <th width="5%" scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">CANTD</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody className="divide-y divide-gray-200">
                                                         {ArrayDataGerenciaSolicitud.length>0 ? (
                                                            <>
                                                                  {ArrayDataGerenciaSolicitud.map(function(d,i) {
                                                                  return (
                                                                     <tr key={i}>
                                                                        <td className="px-2 py-0.5 text-xs border border-slate-300 text-left"><span className="">{d.gerencia}</span></td>
                                                                        <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.normal>0 ? d.normal:"-"}</span></td>
                                                                        <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.dieta>0 ? d.dieta:"-"}</span></td>
                                                                        <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.especial>0 ? d.especial:"-"}</span></td>
                                                                        <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.cantd}</span></td>
                                                                     </tr>
                                                                  )
                                                                  })}
                                                                  {ArrayDataTGerenciaSolicitud.length > 0 &&
                                                                  <>
                                                                     {ArrayDataTGerenciaSolicitud.map(function(d,i) {
                                                                        return (
                                                                              <tr  key={i} className="bg-sky-900">
                                                                              <td className="px-6 text-sm border border-slate-300 font-bold text-right text-white"><span className="text-lg">{d.Tgerencia}</span></td>
                                                                              <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tnormal>0 ? d.Tnormal:"-"}</span></td>
                                                                              <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tdieta>0 ? d.Tdieta:"-"}</span></td>
                                                                              <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tespecial>0 ? d.Tespecial:"-"}</span></td>
                                                                              <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tcantd}</span></td>
                                                                              </tr>
                                                                        )
                                                                     })}
                                                                  </>
                                                                  }
                                                            </>
                                                         ):
                                                            <tr>
                                                                  <td colSpan={5} className="px-2 py-1 text-xs border border-slate-300 text-center">No hay coincidencias</td>
                                                            </tr>
                                                         }
                                                      </tbody>
                                                   </table>
                                                </div>
                                                <div className="overflow-hidden border rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                   <thead className="bg-sky-900">
                                                         <tr>
                                                            <th colSpan={4} scope="col" className="px-3 py-1 text-xs border border-slate-300 text-center text-white">
                                                               <div className="flex flex-col items-center justify-center">
                                                                  <div className='flex justify-around items-center mb-2'>
                                                                     <div className='flex flex-col mx-2'>
                                                                        <span className='text-sm font-black underline'>RELACIÓN SOLICITUD</span>
                                                                        <span>{TableCoincidencia.fecha_solicitud}</span>
                                                                     </div>
                                                                     <div className='flex flex-col mx-2'>
                                                                        <span className='text-sm font-black underline'>CLIENTE</span>
                                                                        <span>{TableCoincidencia.nombre_cliente}</span>
                                                                     </div>
                                                                     <div className='flex flex-col mx-2'>
                                                                        <span className='text-sm font-black underline'>SUCURSAL</span>
                                                                        <span>{TableCoincidencia.nombre_sucursal}</span>
                                                                     </div>
                                                                     <div className='flex flex-col mx-2'>
                                                                        <span className='text-sm font-black underline'>SERVICIO</span>
                                                                        <span>{TableCoincidencia.nombre_servicio}</span>
                                                                     </div>
                                                                  </div>
                                                                  <div>EDICIÓN: {TableCoincidencia.fecha_sistema} {TableCoincidencia.hora_sistema}</div>
                                                               </div>
                                                            </th>
                                                         </tr>
                                                         <tr>
                                                            <th width="40%" scope="col" className="px-1 py-3 text-sm border border-slate-300 font-bold text-center text-white">NOMBRES Y APELLIDOS</th>
                                                            <th width="10%" scope="col" className="px-1 py-3 text-sm border border-slate-300 font-bold text-center text-white">COMENSAL</th>
                                                            <th width="auto%" scope="col" className="px-1 py-3 text-sm border border-slate-300 font-bold text-center text-white">GERENCIA</th>
                                                            <th width="auto%" scope="col" className="px-1 py-3 text-sm border border-slate-300 font-bold text-center text-white">RESPONSABLE</th>
                                                         </tr>
                                                   </thead>
                                                   <tbody className="divide-y divide-gray-200">
                                                         {ArraySolicitudServicio.length>0 ? (
                                                            ArraySolicitudServicio.map(function(d,i) {
                                                               return (
                                                                  <tr key={i}>
                                                                     <td className="px-1 py-0.5 text-xs border border-slate-300 text-left"><div className="flex flex-col"><span className="font-bold">{d.nombres_personal} {d.apellidos_personal}</span><span className="font-light">C.I.: {new Intl.NumberFormat('es-ES').format(d.nrocedula_personal)}</span><span className="font-light">Cgo: {d.nombre_cargo}</span></div></td>
                                                                     <td className="px-1 py-0.5 text-xs border border-slate-300 text-center"><div className="flex flex-col"><span><span className="font-light">{d.nombre_tipocomensal}</span></span></div></td>
                                                                     <td className="px-1 py-0.5 text-xs border border-slate-300 text-center"><div className="flex flex-col"><span className="font-light">{d.nombre_gerencia}</span></div></td>
                                                                     <td className="px-1 py-0.5 text-xs border border-slate-300 text-left"><div className="flex flex-col"><span className="font-light">{d.email_usuario.toLowerCase()}</span><span className="font-light">{d.datetime_solicitudcomensal}</span></div></td>
                                                                  </tr>
                                                               )
                                                            })
                                                         ):
                                                            <tr>
                                                               <td colSpan={4} className="px-2 py-1 text-xs border border-slate-300 text-center">No hay coincidencias</td>
                                                            </tr>
                                                         }
                                                   </tbody>
                                                </table>
                                                </div>
                                            </div>                                          
                                        </div>
                                    </>
                                 }
                              </form>
                           )}
                        </Formik>
                        
                     </fieldset>
                  </div>
                  {/* ############################### FIN BODY ################################### */}
               </>
            :
               <Nousuario user={user.email_usuario}/>
            }
         </>
      </Suspense>
   )
}

export default RelacionServicioComida