import { useContext, useState, useEffect, Suspense } from 'react'
import { UserContext } from '../../../context/UserContext'
import { BsFillTrashFill, BsFillPersonCheckFill, BsFillPeopleFill, BsFillPersonXFill, BsFillBackspaceFill,BsFillSendFill, BsFill0SquareFill, BsFill1SquareFill, BsFill2SquareFill, BsFill3SquareFill, BsFill4SquareFill, BsFill5SquareFill, BsFill6SquareFill, BsFill7SquareFill, BsFill8SquareFill, BsFill9SquareFill, BsFillCpuFill, BsFillQuestionCircleFill, BsFillCheckCircleFill, BsFillXCircleFill} from "react-icons/bs"
import {BiLoaderAlt} from "react-icons/bi"
import { Formik } from "formik"
import Spinner from "../../modal/Spinner";
import Nousuario from "../../modal/NoUsuario";
import { SeparateDateSelect } from "../../funciones/WorkDate"
import { Axios } from '../../../context/UserContext';

const ControlServicio = (props) => {
   const {user, asignacion} = useContext(UserContext)
   const [ShowSpinner, setShowSpinner] = useState(false)
   const [ShowTeclado, setShowTeclado] = useState(false)
   const [ArrayCliente, setArrayCliente] = useState(asignacion.clientes)
   const [ArraySucursal, setArraySucursal] = useState({})
   const [AsigSucursal, setAsigSucursal] = useState(asignacion.sucursales)
   const [ArrayServicio, setArrayServicio] = useState({})
   const [AsigServicio, setAsigServicio] = useState(asignacion.servicios)
   const [AsigGerencia, setAsigGerencia] = useState({})
   const [ArrayRegistroServicio, setArrayRegistroServicio] = useState({})
   const [ArrayTipoComensal, setArrayTipoComensal] = useState({})
   const [ArrayDataGerenciaRegistro, setArrayDataGerenciaRegistro] = useState({})
   const [ArrayDataTGerenciaRegistro, setArrayDataTGerenciaRegistro] = useState([])
   const [OpenFuncion, setOpenFuncion] = useState("")
   const [Aviso, setAviso] = useState({Show:false})
   const [TableCoincidencia, setTableCoincidencia] = useState({Show:false})
   const [isActive, setIsActive] = useState(false);
   const [intervaloId, setIntervaloId] = useState(null);

   const CerrarAviso = (login) => {
      setAviso({Show:false})
   }
   let tiempo=0
   let Tregistros=0
   const BuscarCoincidencia = (e)=>{
      const fecha = document.getElementById('fecha').value;
      const fechasel= SeparateDateSelect(fecha)
      const id_cliente = document.getElementById('cliente').value;
      const id_sucursal = document.getElementById('sucursal').value;
      const id_servicio = document.getElementById('servicio').value;
      const tipo_peticion = 1
      
      if(fecha!="" && id_cliente!="" && id_sucursal!="" && id_servicio!=""){
         setShowSpinner(true)
         tiempo++
         async function main() {
            try{
               const {data} = await Axios.post('/registrocomensal.php',{
                  fechasel, id_cliente, id_sucursal, id_servicio, tipo_peticion, AsigGerencia
               });
               /* console.log("data-registro", data) */
               if(data.success){
                  const array_registro = data.datos;
                  const array_gerencia = [];
                  const array_data_gerencia = [];
                  const array_data_Tgerencia = [];
                  if(tiempo==1){
                     Tregistros=data.datos.length
                  }
                  /* console.log("tiempo", tiempo)
                  console.log("Tregistros", Tregistros)
                  console.log("array_registro.length", array_registro.length) */
                  if(Tregistros<array_registro.length){
                     Tregistros=data.datos.length
                     tiempo=tiempo-tiempo
                  }
                  if(tiempo==75 && (Tregistros==array_registro.length)){
                     setIntervaloId(null)
                     setIsActive(false)
                     tiempo=tiempo-tiempo
                  }

                  array_registro.sort(function (b, a) {
                     if (new Date(a.datetime_registrocomensal) > new Date(b.datetime_registrocomensal)) {return 1;}
                     if (new Date(a.datetime_registrocomensal) < new Date(b.datetime_registrocomensal)) {return -1;}
                     return 0;
                  });
                  setArrayRegistroServicio(array_registro)

                  array_registro.forEach(element => {
                     array_gerencia.push(element.id_gerencia)
                  });
                  let result_array_gerencia = array_gerencia.filter((item,index)=>{
                     return array_gerencia.indexOf(item) == index;
                  })

                  let Ttotal=0
                  let Tnormal=0
                  let Tdieta=0
                  let Tespecial=0
                  let Tsininfo=0
                  let TSIsolicitado=0
                  let TNOsolicitado=0
                  let TSIduplicado=0

                  result_array_gerencia.forEach(ge => {
                     let sum=0
                     let normal=0
                     let dieta=0
                     let especial=0
                     let sininfo=0
                     let nombre_ge=null
                     array_registro.forEach(so => {
                        if(ge==so.id_gerencia){
                           Ttotal++
                           sum++
                           if(so.nombre_gerencia==null){nombre_ge='SIN/INFO'}else{nombre_ge=so.nombre_gerencia}
                           switch (so.id_tipocomensal) {
                                 case 1:
                                    normal++
                                    Tnormal++
                                    break;
                                 case 2:
                                    dieta++
                                    Tdieta++
                                    break;
                                 case 3:
                                    especial++
                                    Tespecial++
                                    break;
                                 case null:
                                    sininfo++
                                    Tsininfo++
                                    break;
                           }
                           switch (so.solicitado_registrocomensal) {
                                 case 1:
                                    TSIsolicitado++
                                    break;
                                 default:
                                    TNOsolicitado++
                                    break;
                           }
                           switch (so.duplicado_registrocomensal) {
                                 case 1:
                                    TSIduplicado++
                                    break;
                           }
                        }
                     });

                     array_data_gerencia.push({
                        gerencia:nombre_ge,
                        normal:normal,
                        dieta:dieta,
                        especial:especial,
                        sininfo:sininfo,
                        cantd:sum,
                     })
                  });

                  array_data_Tgerencia.push({
                     Tgerencia:"TOTAL",
                     Tnormal:Tnormal,
                     Tdieta:Tdieta,
                     Tespecial:Tespecial,
                     Tsininfo:Tsininfo,
                     Tcantd:Ttotal,
                     TSIsolicitado:TSIsolicitado,
                     TNOsolicitado:TNOsolicitado,
                     TSIduplicado:TSIduplicado,
                  })
                  setArrayDataGerenciaRegistro(array_data_gerencia)
                  if(array_data_gerencia.length >0){
                     setArrayDataTGerenciaRegistro(array_data_Tgerencia)
                  }else{
                     setArrayDataTGerenciaRegistro({})
                  }
               }
            } catch(err){ console.log("err");console.log(err) } finally {setShowSpinner(false)}
         }
         main()
      }
   }
   const ChangeFecha = (e)=>{
      setArraySucursal({ Show:false,Datos:'' })
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false})
      
   }
   const ChangeCliente = async(e)=>{
      setArraySucursal({ Show:false,Datos:'' })
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false})
      
      const id_cliente = document.getElementById('cliente').value;
      if(id_cliente!=''){
         setShowSpinner(true)
         async function task1() {
            try{
               const {data} = await Axios.post('/sucursal_consultaporcliente.php',{
                  id_cliente, AsigSucursal
               });
               if(data.success==1){
                  setArraySucursal({
                     Show:true,
                     Datos:data.datos
                  })
               }
            } catch(err){ console.log("err");console.log(err) }
         }
         async function task2() {
            try{
               const {data} = await Axios.post('/tipocomensal.php',{});
               /* console.log("data-comensal", data) */
               if(data.success==1){
                  setArrayTipoComensal(data.datos)
               }
               
            } catch(err){ console.log("err");console.log(err) }
         }
         async function main() {
            await Promise.all([task1(), task2()]);
            setShowSpinner(false)
         }
         main()
      }
   }   
   const ChangeSucursal = async(e)=>{
      setShowSpinner(true)
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false,})
      
      const id_sucursal = document.getElementById('sucursal').value;

      try{
         const {data} = await Axios.post('/servicio_consultaporsucursal.php',{
            id_sucursal, AsigServicio
         });
         /* console.log("data-servicio")
         console.log(data) */
         if(data.success==1){
            setArrayServicio({
               Show:true,
               Datos:data.datos
            })
         }
      } catch(err){ console.log("err");console.log(err) }finally {setShowSpinner(false);}
   }
   const ChangeServicio = async(e)=>{
      setTableCoincidencia({Show:false})
      
      const fecha = document.getElementById('fecha').value;
      const id_cliente = document.getElementById('cliente').value;
      const id_sucursal = document.getElementById('sucursal').value;
      const id_servicio = document.getElementById('servicio').value;

      if(fecha!="" && id_servicio!=""){
         setShowSpinner(true)
         const [ySel, mSel, dSel]=fecha.split('-')
         const fechaSel=new Date(ySel,mSel-1,dSel)
         let HoInicio=""
         let HoFin=""
         try{
            const {data} = await Axios.post('/consultaGrupoFechaHora.php',{
               id_servicio
            });
            /* console.log("data- fecha/hora", data) */
            const [ySis, mSis, dSis]=data.fecha.split('-')
            const [hSis, iSis, sSis]=data.hora.split(':')
            const fechaSis=new Date(ySis,mSis-1,dSis)

            if(fechaSel.getTime() < fechaSis.getTime()){
               setAviso({
                  Show:true,
                  Logo:'BsFillXCircleFill',
                  Color:'red-500',
                  Texto:`El Registro de Comensal\n"NO" está disponible\n\nLa fecha es anterior a hoy`,
                  AlignTexto:true,
                  Botones:{
                     Bcerrar:true,
                  }
               })
            }else if(fechaSel.getTime() > fechaSis.getTime()){
               setAviso({
                  Show:true,
                  Logo:'BsFillXCircleFill',
                  Color:'red-500',
                  Texto:`El Registro de Comensal\n"NO" está disponible\n\nLa fecha es posterior a hoy`,
                  AlignTexto:true,
                  Botones:{
                     Bcerrar:true,
                  }
               })
            }else{

               ArrayServicio.Datos.some(element => {
                  if(element.id_servicio==id_servicio){
                      HoInicio=element.HoInicio
                      HoFin=element.HoFin
                      return true
                  }
               });
               const [hSelIn, iSelIn, sSelIn]=HoInicio.split(':')
               const [hSelFin, iSelFin, sSelFin]=HoFin.split(':')
               
               let fechaSis2=new Date(ySis,mSis-1,dSis,hSis,iSis)
               let fechaSelIn=new Date(ySel,mSel-1,dSel,hSelIn,iSelIn)
               let fechaSelFin=new Date(ySel,mSel-1,dSel,hSelFin,iSelFin)
               
               const hRIn = (fechaSelIn.getHours()<=9 ? '0'+fechaSelIn.getHours() :fechaSelIn.getHours())
               const iRIn = (fechaSelIn.getMinutes()<=9 ? '0'+fechaSelIn.getMinutes() :fechaSelIn.getMinutes())
               const hRFin = (fechaSelFin.getHours()<=9 ? '0'+fechaSelFin.getHours() :fechaSelFin.getHours())
               const iRFin = (fechaSelFin.getMinutes()<=9 ? '0'+fechaSelFin.getMinutes() :fechaSelFin.getMinutes())
               /* console.log("fechaSis2",fechaSis2)
               console.log("fechaSelIn", fechaSelIn)
               console.log("fechaSelFin", fechaSelFin) */
               if((fechaSis2.getTime() >= fechaSelIn.getTime()) && (fechaSis2.getTime()<= fechaSelFin.getTime())){
                  setShowTeclado(true)
                  let nombre_cliente = ''
                  let nombre_sucursal = ''
                  let nombre_servicio = ''
                  let HoInicio = ''
                  let HoFin = ''
                  ArrayCliente.some(element => {
                     if(element.id_cliente==id_cliente){
                        nombre_cliente=element.nombre_cliente
                        return true
                     }
                  });
                  ArraySucursal.Datos.some(element => {
                     if(element.id_sucursal==id_sucursal){
                        nombre_sucursal=element.nombre_sucursal
                        return true
                     }
                  });
                  ArrayServicio.Datos.some(element => {
                     if(element.id_servicio==id_servicio){
                        nombre_servicio=element.nombre_servicio
                        HoInicio=element.HoInicio
                        HoFin=element.HoFin
                        return true
                     }
                  });
                  
                  setTableCoincidencia({
                     Show:true,
                     fecha_solicitud:dSel+'-'+mSel+'-'+ySel,
                     fecha_sistema:dSis+'-'+mSis+'-'+ySis,
                     hora_sistema:hSis+':'+iSis+':'+sSis,
                     nombre_cliente: nombre_cliente,
                     nombre_sucursal: nombre_sucursal,
                     nombre_servicio: nombre_servicio,
                     HoInicio: HoInicio,
                     HoFin: HoFin,
                     id_cliente: id_cliente,
                     id_sucursal: id_sucursal,
                     id_servicio: id_servicio,
                  })
                  BuscarCoincidencia()
               }else if (fechaSis2.getTime() < fechaSelIn.getTime()){
                  setAviso({
                     Show:true,
                     Logo:'BsFillXCircleFill',
                     Color:'red-500',
                     Texto:`El Registro de Comensal\n"NO" está disponible\n\nComienza a las ${hRIn}:${iRIn}`,
                     AlignTexto:true,
                     Botones:{
                        Bcerrar:true,
                     }
                  })
               }else{
                  setAviso({
                     Show:true,
                     Logo:'BsFillXCircleFill',
                     Color:'red-500',
                     Texto:`El Registro de Comensal\n"NO" está disponible\n\nCulminó a las ${hRFin}:${iRFin}`,
                     AlignTexto:true,
                     Botones:{
                        Bcerrar:true,
                     }
                  })
               }
            }
         } catch(err){ console.log("err");console.log(err) }finally {setShowSpinner(false); }
      }

   }
   const ExtraerHora=(time)=>{
    const [Fecha, Hora]=time.split(' ')
    const [h,m,s]= Hora.split(':')
    const Time= h+':'+m+':'+s
    return Time

   }
   const IniciarDetener = () => {
      setIsActive(!isActive);
      if (!isActive) {
         /* const id = setInterval(BuscarCoincidencia, 4000); */
         const id = setInterval(ChangeServicio, 4000);
         setIntervaloId(id);
      } else {
         clearInterval(intervaloId);
      }
   }
   const ChangeTipoComensal = async(event, registro, cedula)=>{
      const id_registro = parseInt(registro)
      const nrocedula = parseInt(cedula)
      const id_tipocomensal = event.target.value;
      const fecha = document.getElementById('fecha').value;
      const fechasel= SeparateDateSelect(fecha)
      const id_cliente = document.getElementById('cliente').value;
      const selectElement = document.getElementById(''+registro+'_'+cedula+'');
      selectElement.style.display = 'none';

      try{
         const {data} = await Axios.post('/servicio_cambiartipocomensal.php',{
            fechasel, id_registro, id_cliente, nrocedula, id_tipocomensal
         });
         console.log("data-change tipocomensal", data)
         if(data.success==1){
            
         }
      } catch(err){ console.log("err");console.log(err) }
   }
   useEffect(() => {
      return () => {
            if (intervaloId) {
               clearInterval(intervaloId);
            }
      };
   }, [intervaloId]);
   return(
      <Suspense fallback={<Spinner />}>
         <>
            {ShowSpinner && <Spinner/>}
            {Aviso.Show &&
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     {/* <div className="relative min-w-sm my-6 mx-auto max-w-sm"> */}
                     <div className="relative my-6 mx-2 w-full md:w-7/10 lg:w-1/2">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                           {/*header*/}
                           <div className="flex justify-center items-center rounded-t">
                              {Aviso.Logo=="BsFillQuestionCircleFill" && <span className="p-2"><BsFillQuestionCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillCheckCircleFill" && <span className="p-2"><BsFillCheckCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}    
                              {Aviso.Logo=="BsFillXCircleFill" && <span className="p-2"><BsFillXCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillPersonCheckFill" && <span className="p-2"><BsFillPersonCheckFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillPersonXFill" && <span className="p-2"><BsFillPersonXFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillPeopleFill" && <span className="p-2"><BsFillPeopleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                           </div>
                           {/*body*/}
                           <div className="relative p-4 flex-auto text-white">
                              {Aviso.Texto &&
                                 <p className={`${(Aviso.AlignTexto ? "text-center" : "text-justify")} my-1 font-bold text-xl whitespace-pre-line`}>
                                    {Aviso.Texto}
                                 </p>
                              }
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-center rounded-b px-2">
                              <div className="flex items-center justify-between">
                                 {Aviso.Botones.Bcerrar && <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={()=>CerrarAviso(Aviso.Login)}>Cerrar</button>}
                                 {/* {Aviso.Botones.Benviar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>} */}
                                 {/* {Aviso.Botones.Beliminar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>} */}
                                 {/* {Aviso.Botones.BCargarCedula && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border-2 text-xl" type="button" onClick={()=>CargarCedula()}>Si</button>} */}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
               </>
            }

            {user.status_usuario ?
               <>
                  {/* ############################### BODY ################################### */}
                  <div className="flex flex-row items-center bg-sky-900 py-2 px-4 text-xl text-white font-bold">
                     <span className="font-bold text-lg"><BsFillCpuFill/></span>
                     <span className="ml-2 text-2xl text-white">Control Servicio</span>
                  </div>
                  <div className={` ${ShowTeclado ? "hidden" : "block"} flex flex-col justify-center items-center h-full ease-out duration-300`}>
                        <Formik
                           initialValues={{
                              fecha:'',
                              cliente:'',
                              sucursal:'',
                              servicio:'',
                              buscar:'',
                              cedula:[]
                           }}
                           validate={(valores) =>{
                              
                              let errores={}
                              if(!valores.fecha){
                                 errores.fecha='Por favor, seleccione una fecha'
                              }
                              if(!valores.cliente){
                                 errores.cliente='Por favor, seleccione un item'
                              }
                              if(!valores.sucursal){
                                 errores.sucursal='Por favor, seleccione un item'
                              }
                              if(!valores.servicio){
                                 errores.servicio='Por favor, seleccione un item'
                              }
                              return errores
                           }}
                           onSubmit = {async (valores,{resetForm}) => {
                              /* resetForm({ 
                                 values: { 
                                    fecha:valores.fecha,
                                    cliente:valores.cliente,
                                    sucursal:valores.sucursal,
                                    servicio:''
                                 },
                              }); */
                           }}
                        >
                        {({values, errors, touched, handleSubmit, handleChange, handleBlur}) =>(
                           <form onSubmit={handleSubmit}>
                              <div className="flex flex-col justify-between items-center m-4">
                                 <div className='w-full md:w-7/10 lg:w-auto'>
                                    <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="fecha" className="font-bold text-white mb-2">Fecha</label>
                                       <input 
                                                type="date" 
                                                id="fecha" 
                                                name="fecha"
                                                value={values.fecha}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                                onChange={event=>{ handleChange(event); ChangeFecha(event); }}
                                                onClick={(event) => {
                                                values.cliente=""
                                                values.sucursal=""
                                                values.servicio=""
                                                }}
                                                onBlur={event =>{handleBlur(event); }}
                                       />
                                       {touched.fecha && errors.fecha && <div className="text-sm font-semibold text-red-500 italic">{errors.fecha}</div>}
                                    </div>
                                    <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                          <label htmlFor="cliente" className="font-bold text-white mb-2">Cliente</label>
                                          <select 
                                                id="cliente" 
                                                name="cliente"
                                                value={values.cliente}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                                onChange={event=>{ handleChange(event); ChangeCliente(event); }}
                                                onClick={(event) => {
                                                values.sucursal=""
                                                values.servicio=""
                                                }}
                                                onBlur={handleBlur}
                                          >
                                                <option value="">---Selección---</option>
                                                {ArrayCliente.length>0 && (
                                                ArrayCliente.map(function(d,i) {
                                                      return (
                                                            <option key={"cliente_"+i} value={d.id_cliente}>{d.nombre_cliente}</option>
                                                      )
                                                })
                                                )}
                                          </select>
                                          {touched.cliente && errors.cliente && <div className="text-sm font-semibold text-red-500 italic">{errors.cliente}</div>}
                                    </div>
                                    <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="sucursal" className="font-bold text-white mb-2">Sucursal</label>
                                       <select 
                                                id="sucursal" 
                                                name="sucursal"
                                                value={values.sucursal}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                                onChange={event=>{ handleChange(event); ChangeSucursal(event); }}
                                                onClick={(event) => {
                                                values.servicio=""
                                                }}
                                                onBlur={handleBlur}
                                       >
                                                <option value="">---Selección---</option>
                                                {ArraySucursal.Show && (
                                                ArraySucursal.Datos.map(function(d,i) {
                                                   return (
                                                      <option key={"sucursal_"+i} value={d.id_sucursal}>{d.nombre_sucursal}</option>
                                                   )
                                                })
                                                )}
                                       </select>
                                       {touched.sucursal && errors.sucursal && <div className="text-sm font-semibold text-red-500 italic">{errors.sucursal}</div>}
                                    </div>
                                    <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                       <label htmlFor="servicio" className="font-bold text-white mb-2">Servicio</label>
                                       <select 
                                                id="servicio" 
                                                name="servicio"
                                                value={values.servicio}
                                                onChange={event=>{ handleChange(event); ChangeServicio(event);}}
                                                onBlur={handleBlur}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                       >
                                                <option value="">---Selección---</option>
                                                {ArrayServicio.Show && (
                                                ArrayServicio.Datos.map(function(d,i) {
                                                   return (
                                                      <option key={"servicio_"+i} value={d.id_servicio}>{d.nombre_servicio}</option>
                                                   )
                                                })
                                                )}
                                       </select>
                                       {touched.servicio && errors.servicio && <div className="text-sm font-semibold text-red-500 italic">{errors.servicio}</div>}
                                    </div>
                                 </div>
                              </div>
                           </form>
                        )}
                        </Formik>
                  </div>
                  {ShowTeclado &&
                     <>
                        <div className="flex flex-col justify-between mb-1 ease-in duration-300">
                           <div className="flex flex-row justify-between items-center mx-1 mt-1 bg-sky-900 px-4 py-1 text-white">
                              <div className="flex flex-col justify-center items-center">
                                    <label><input type="checkbox" checked={isActive} onChange={IniciarDetener}/></label>
                                    <label>{isActive ? "DETENER" : "INICIAR"}</label>
                              </div>
                              <div className="flex flex-col justify-center items-center">
                                    <div className="font-bold font-xl"><label>FECHA</label></div>
                                    <div><label>{TableCoincidencia.fecha_solicitud}</label></div>
                              </div>
                              <div className="flex flex-col justify-center items-center">
                                    <div className="font-bold font-xl"><label>CLIENTE</label></div>
                                    <div><label>{TableCoincidencia.nombre_cliente}</label></div>
                              </div>
                              <div className="flex flex-col justify-center items-center">
                                    <div className="font-bold font-xl"><label>SUCURSAL</label></div>
                                    <div><label>{TableCoincidencia.nombre_sucursal}</label></div>
                              </div>
                              <div className="flex flex-col justify-center items-center">
                                    <div className="font-bold font-xl"><label>SERVICIO</label></div>
                                    <div><label>{TableCoincidencia.nombre_servicio}</label></div>
                              </div>
                              <div className="flex justify-center items-center p-1"><button type="button" className="text-3xl text-red-900" onClick={()=>setShowTeclado(false)}><BsFillXCircleFill/></button></div>
                           </div>
                        </div>
                        {ArrayDataGerenciaRegistro.length>0 &&
                           <>
                              <div className="p-1.5 w-full flex flex-row justify-around items-center">
                                 <table className="min-w-auto divide-y divide-gray-200">
                                    <thead>
                                       <tr>
                                          <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center bg-green-900 text-white">
                                             <div className="flex justify-center align-center text-lg">
                                                <BsFillPersonCheckFill/>
                                             </div>
                                          </th>
                                          <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center bg-red-900 text-white">
                                             <div className="flex justify-center align-center text-lg text-white">
                                                <BsFillPersonXFill/>
                                             </div>
                                          </th>
                                          <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white bg-sky-900">CANTD</th>
                                          <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center bg-orange-600 text-white">
                                             <div className="flex justify-center align-center text-lg text-white">
                                                <BsFillPeopleFill/>
                                             </div>
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                          {ArrayDataTGerenciaRegistro.map(function(d,i) {
                                             return (
                                                <tr  key={"servicio_"+i}>
                                                   <td className="px-6 text-sm border border-slate-300 font-bold text-center"><span className="text-lg">{d.TSIsolicitado>0 ? d.TSIsolicitado:"-"}</span></td>
                                                   <td className="px-6 text-sm border border-slate-300 font-bold text-center"><span className="text-lg">{d.TNOsolicitado>0 ? d.TNOsolicitado:"-"}</span></td>
                                                   <td className="px-6 text-sm border border-slate-300 font-bold text-center"><span className="text-lg">{d.Tcantd}</span></td>
                                                   <td className="px-6 text-sm border border-slate-300 font-bold text-center"><span className="text-lg">{d.TSIduplicado>0 ? d.TSIduplicado+"/"+d.Tcantd:"-"}</span></td>
                                                </tr>
                                             )
                                          })}
                                    </tbody>
                                 </table>
                              </div>
                           </>
                        }
                        {/* ################################################################################## */}
                        <div className='p-1 min-w-full max-h-80 overflow-y-auto'>
                           <table className="min-w-full divide-y border-gray-200 divide-gray-200">
                              <thead className="bg-sky-900 sticky top-0">
                                    <tr>
                                       <th width="14%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">HORA</th>
                                       <th width="auto%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-left text-white">IDENTIFICACIÓN</th>
                                       <th width="10%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">TIPO</th>
                                       <th width="5%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">SOLICITADA</th>
                                       <th width="5%" scope="col" className="px-3 py-3 text-sm border border-slate-300 font-bold text-center text-white">DUPLICADO</th>
                                    </tr>
                              </thead>
                              <tbody className='min-w-full'>
                                    {ArrayRegistroServicio.length>0 ? (
                                       ArrayRegistroServicio.map(function(d,i) {
                                          return (
                                                <tr key={"Re_"+i}>
                                                   <td className={` ${i==0 ? "text-2xl border bg-black text-white font-extrabold" : "text-xs border text-black"} px-2 py-1 border-slate-300 text-center`}><div className="flex flex-col"><span className="font-bold">{ExtraerHora(d.datetime_registrocomensal)}</span></div></td>
                                                   <td className={` ${i==0 ? "text-2xl border bg-black text-white font-extrabold" : "text-xs border text-black"} px-2 py-1 border-slate-300 text-left`}><div className="flex flex-col"><span className="font-bold">{d.nombres_personal} {d.apellidos_personal}</span><span className="font-light">C.I.: {new Intl.NumberFormat('es-ES').format(d.nrocedula_registrocomensal)}</span><span className="font-light">{d.nombre_cargo}</span><span className="font-light">{d.nombre_gerencia}</span></div></td>
                                                   <td className={` ${i==0 ? "text-2xl border bg-black text-white font-extrabold" : "text-xs border text-black"} px-2 py-1 border-slate-300 text-center`}>
                                                      <div className="flex flex-col justify-center items-center">
                                                            {d.nombre_tipocomensal!=null ?
                                                               <span className="font-light">{d.nombre_tipocomensal}</span>
                                                            :
                                                            <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                                               <select
                                                                  id={`${d.id_registrocomensal}_${d.nrocedula_registrocomensal}`}
                                                                  className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                                                  onChange={(event)=>ChangeTipoComensal(event, d.id_registrocomensal, d.nrocedula_registrocomensal)}
                                                               >
                                                                  <option value="">---Selección---</option>
                                                                  {ArrayTipoComensal.map(function(d,i) {
                                                                     return (
                                                                        <option key={"tipocomensal_"+i} value={d.id_tipocomensal}>{d.nombre_tipocomensal}</option>
                                                                     )
                                                                  })}
                                                               </select>
                                                            </div>
                                                            }
                                                      </div>
                                                   </td>
                                                   {d.solicitado_registrocomensal==0 ?
                                                      <td className="px-2 py-1 border border-slate-300 font-extrabold text-center bg-red-900">
                                                            <div className="flex justify-center align-center text-6xl text-white">
                                                               <BsFillPersonXFill/>
                                                            </div>
                                                      </td>
                                                   :
                                                      <td className="px-2 py-1 border border-slate-300 font-extrabold text-center bg-green-900">
                                                            <div className="flex justify-center align-center text-6xl text-white">
                                                               <BsFillPersonCheckFill/>
                                                            </div>
                                                      </td>
                                                   }
                                                   {d.duplicado_registrocomensal==0 ?
                                                      <td className="px-2 py-1 border bg-black border-slate-300 font-extrabold text-center"></td>
                                                   :
                                                      <td className="px-2 py-1 border border-slate-300 font-extrabold text-center bg-orange-600">
                                                            <div className="flex justify-center align-center text-6xl text-white">
                                                               <BsFillPeopleFill/>
                                                            </div>
                                                      </td>
                                                   }
                                                </tr>
                                          )
                                       }))
                                    :
                                          <tr>
                                                <td colSpan={5} className="px-2 py-1 text-xs border border-slate-300 text-center">No hay coincidencias</td>
                                          </tr>
                                    }
                              </tbody>
                           </table>
                        </div>
                        {/* ################################################################################## */}
                        <div className="p-1.5 w-full flex flex-col justify-center items-center mt-4">
                           {/* ################################################################################## */}
                           <table className="min-w-auto divide-y divide-gray-200">
                                 <thead className="bg-sky-900">
                                    <tr>
                                       <td scope="col" colSpan={6} className="px-2 py-1 text-xs border border-slate-300 text-center text-white">
                                             <div className="flex flex-col items-center justify-center">
                                                <div className='w-full flex justify-between items-center mb-2'>
                                                   <div className='flex flex-col mx-2'>
                                                      <span className='text-sm font-black underline'>RELACIÓN SOLICITUD</span>
                                                      <span>{TableCoincidencia.fecha_solicitud}</span>
                                                   </div>
                                                   <div className='flex flex-col mx-2'>
                                                      <span className='text-sm font-black underline'>CLIENTE</span>
                                                      <span>{TableCoincidencia.nombre_cliente}</span>
                                                   </div>
                                                   <div className='flex flex-col mx-2'>
                                                      <span className='text-sm font-black underline'>SUCURSAL</span>
                                                      <span>{TableCoincidencia.nombre_sucursal}</span>
                                                   </div>
                                                   <div className='flex flex-col mx-2'>
                                                      <span className='text-sm font-black underline'>SERVICIO</span>
                                                      <span>{TableCoincidencia.nombre_servicio}</span>
                                                   </div>
                                                </div>
                                                <div><span className='text-sm font-black underline'>EDICIÓN:</span> {TableCoincidencia.fecha_sistema} {TableCoincidencia.hora_sistema}</div>
                                             </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">GERENCIA</th>
                                       <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">NORMAL</th>
                                       <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">DIETA</th>
                                       <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">ESPECIAL</th>
                                       <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">SIN/INFO</th>
                                       <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white">CANTD</th>
                                    </tr>
                                 </thead>
                                 <tbody className="divide-y divide-gray-200">
                                    {ArrayDataGerenciaRegistro.length>0 ? (
                                       <>
                                             {ArrayDataGerenciaRegistro.map(function(d,i) {
                                                return (
                                                   <tr key={"DGR_"+i}>
                                                         <td className="px-2 py-0.5 text-xs border border-slate-300 text-left"><span className="">{d.gerencia}</span></td>
                                                         <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.normal>0 ? d.normal:"-"}</span></td>
                                                         <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.dieta>0 ? d.dieta:"-"}</span></td>
                                                         <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.especial>0 ? d.especial:"-"}</span></td>
                                                         <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.sininfo>0 ? d.sininfo:"-"}</span></td>
                                                         <td className="px-2 py-0.5 text-xs border border-slate-300 text-center"><span className="">{d.cantd}</span></td>
                                                   </tr>
                                                )
                                             })}
                                             {ArrayDataTGerenciaRegistro.length> 0 && (
                                                <>
                                                   {ArrayDataTGerenciaRegistro.map(function(d,i) {
                                                      return (
                                                         <tr key={"DTGR_"+i} className="bg-sky-900">
                                                               <td className="px-6 text-sm border border-slate-300 font-bold text-right text-white"><span className="text-lg">{d.Tgerencia}</span></td>
                                                               <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tnormal>0 ? d.Tnormal:"-"}</span></td>
                                                               <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tdieta>0 ? d.Tdieta:"-"}</span></td>
                                                               <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tespecial>0 ? d.Tespecial:"-"}</span></td>
                                                               <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tsininfo>0 ? d.Tsininfo:"-"}</span></td>
                                                               <td className="px-6 text-sm border border-slate-300 font-bold text-center text-white"><span className="text-lg">{d.Tcantd}</span></td>
                                                         </tr>
                                                      )
                                                   })}
                                                </>
                                             )}
                                       </>
                                    ):
                                       <tr>
                                             <td colSpan={6} className="px-2 py-1 text-xs border border-slate-300 text-center">No hay coincidencias</td>
                                       </tr>
                                    }
                                 </tbody>
                           </table>
                           {ArrayDataGerenciaRegistro.length>0 &&
                              <>
                                 <div className="p-1.5 w-full flex flex-row justify-around items-center mb-8">
                                    <table className="min-w-auto divide-y divide-gray-200">
                                       <thead>
                                          <tr>
                                             <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center bg-green-900 text-white">
                                                <div className="flex justify-center align-center text-lg">
                                                   <BsFillPersonCheckFill/>
                                                </div>
                                             </th>
                                             <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center bg-red-900 text-white">
                                                <div className="flex justify-center align-center text-lg text-white">
                                                   <BsFillPersonXFill/>
                                                </div>
                                             </th>
                                             <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center text-white bg-sky-900">CANTD</th>
                                             <th scope="col" className="px-6 py-3 text-sm border border-slate-300 font-bold text-center bg-orange-600 text-white">
                                                <div className="flex justify-center align-center text-lg text-white">
                                                   <BsFillPeopleFill/>
                                                </div>
                                             </th>
                                          </tr>
                                       </thead>
                                       <tbody className="divide-y divide-gray-200">
                                       {ArrayDataTGerenciaRegistro.length > 0 && (
                                          <>
                                             {ArrayDataTGerenciaRegistro.map((d, i) => (
                                                   <tr key={`servicio_${i}`}>
                                                      <td className="px-6 text-sm border border-slate-300 font-bold text-center">
                                                         <span className="text-lg">{d.TSIsolicitado > 0 ? d.TSIsolicitado : "-"}</span>
                                                      </td>
                                                      <td className="px-6 text-sm border border-slate-300 font-bold text-center">
                                                         <span className="text-lg">{d.TNOsolicitado > 0 ? d.TNOsolicitado : "-"}</span>
                                                      </td>
                                                      <td className="px-6 text-sm border border-slate-300 font-bold text-center">
                                                         <span className="text-lg">{d.Tcantd}</span>
                                                      </td>
                                                      <td className="px-6 text-sm border border-slate-300 font-bold text-center">
                                                         <span className="text-lg">{d.TSIduplicado > 0 ? d.TSIduplicado : "-"}</span>
                                                      </td>
                                                   </tr>
                                             ))}
                                          </>
                                       )}
                                       </tbody>
                                    </table>
                                 </div>
                              </>
                           }
                        </div>
                        {/* ################################################################################## */}
                     </>
                  }
                  {/* ############################### FIN BODY ################################### */}
               </>
            :
               <Nousuario user={user.email_usuario}/>
            }
         </>
      </Suspense>
   )
}

export default ControlServicio