import { useEffect, useState, useContext } from "react";
import {UserContext} from '../../context/UserContext';

//const date = new Date();

const DiaFechaHora = () =>{
  const {Timestamp} = useContext(UserContext);

  const [dateTime, setDateTime] = useState({
    /* dd: '--',
    mm: '--',
    yy: '--',
    hh: '--',
    ii: '--',
    ss: '--',
    ww: '--', */
  });

  useEffect(() => {
    let DMY=Timestamp.DMY
    let HH=Timestamp.HH
    let [D, M, Y]=DMY.split('-')
    let [h, m, s]=HH.split(':')
    let fechaHora = new Date(Y, M-1, D, h, m, s);
    const timer = setInterval(() => {

        fechaHora.setSeconds(fechaHora.getSeconds() + 1);
        let yyActual = fechaHora.getFullYear();
        let mmActual = fechaHora.getMonth()+1;
        let ddActual = fechaHora.getDate();
        let hhActual = fechaHora.getHours();
        let iiActual = fechaHora.getMinutes();
        let ssActual = fechaHora.getSeconds();
        let wwActual = fechaHora.getDay();

        const weekDays = [
            'Domingo',
            'Lunes',
            'Martes',
            'Miércoles',
            'Jueves',
            'Viernes',
            'Sábado'
        ];
     
        const months = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
        ];

        if(ddActual <10){ddActual= '0'+ddActual}
        if(mmActual <10){mmActual= '0'+mmActual}
        if(hhActual <10){hhActual= '0'+hhActual}
        if(iiActual <10){iiActual= '0'+iiActual}
        if(ssActual <10){ssActual= '0'+ssActual}

        setDateTime({
            dd:       ddActual,
            mm:       mmActual,
            yy:       yyActual,
            hh:       hhActual,
            ii:       iiActual,
            ss:       ssActual,
            ww:       weekDays[wwActual],
        });

    }, 1000);

    return () => clearInterval(timer);
    
  }, []);

  return (
    <>
      {dateTime.ww}<br/>{dateTime.dd}-{dateTime.mm}-{dateTime.yy}<br/>{dateTime.hh}:{dateTime.ii}:{dateTime.ss}
    </>
  );
}

export default DiaFechaHora