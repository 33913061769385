const WeekDaysCor = ['do','lu','ma','mi','ju','vi','sa'];
const WeekDaysCom = ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'];
const WeekDaysComEst = ['domingo','lunes','martes','miercoles','jueves','viernes','sabado'];
const MonthsLetCor = ['ENE','FEB','MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'];
const MonthsLetCom = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
const MonthsNum = ['01','02','03','04','05','06','07','08','09','10','11','12'];

function separateDateAndTimeSis(dateTimeString) {
    const [date, time] = dateTimeString.split('T');
    const [hours, minutes, secondsWithOffset] = time.split(':');
    const [seconds] = secondsWithOffset.split('-');
    const [year, month, day] = date.split('-');
    const offset = parseInt(dateTimeString.slice(-5), 10);
    const localHours = parseInt(hours, 10) + offset / 100;
    const timestamp = new Date(year, month - 1, day, localHours, minutes, seconds);
    const dayOfWeek = timestamp.getDay();
    
    return {
        ddmmyyyA: `${day}-${month}-${year}`,
        ddmmyyyB: `${day}/${month}/${year}`,
        yyyymmddA: `${year}-${month}-${day}`,
        yyyymmddB: `${year}/${month}/${day}`,
        hhmmss: `${hours}:${minutes}:${parseInt(seconds)}`,
        hhmm: `${hours}:${minutes}`,
        pdf:`${WeekDaysCom[dayOfWeek]}, ${day} de ${MonthsLetCom[month-1]} del ${year} a las ${hours}:${minutes}:${parseInt(seconds)}`,
        gf: `${year}${month}${day}`,
        timestamp: timestamp,
    };
}

function separateDateAndTimeSel(date, time) { 
    const [year, month, day] = date.split('-');
    const [hours, minutes, seconds = 0] = time.split(':');

    const timestamp = time == "" ? new Date(year, month - 1, day) : new Date(year, month - 1, day, hours, minutes, seconds);
    const dayOfWeek = timestamp.getDay();

    return {
        ddmmyyyA: `${day}-${month}-${year}`,
        ddmmyyyB: `${day}/${month}/${year}`,
        yyyymmddA: `${year}-${month}-${day}`,
        yyyymmddB: `${year}/${month}/${day}`,
        dayOfWeek: dayOfWeek,
        dayOfWeekEst: WeekDaysComEst[dayOfWeek],
        pdf:`${WeekDaysCom[dayOfWeek]}, ${day} de ${MonthsLetCom[month-1]} del ${year}`,
        gf: `${year}${month}${day}`,
        timestamp: timestamp,
    }
}

function sumarMinutos(horaInicial, minutosASumar) {
    // Separar la hora y los minutos de la hora inicial
    const [horasStr, minutosStr] = horaInicial.split(':');
    const horas = parseInt(horasStr);
    const minutos = parseInt(minutosStr);
  
    // Calcular la nueva hora y minutos
    const minutosResultado = (minutos + minutosASumar) % 60;
    const horasResultado = (horas + Math.floor((minutos + minutosASumar) / 60)) % 24;
  
    // Formatear el resultado
    const minutosResultadoStr = minutosResultado.toString().padStart(2, '0');
    const horasResultadoStr = horasResultado.toString().padStart(2, '0');
  
    return `${horasResultadoStr}:${minutosResultadoStr}`;
}

function SeparateDateSelect(fecha){
    const [y, m, d] = fecha.split('-')
    const sendfecha = y+""+m+""+d
    return sendfecha
}

export {SeparateDateSelect}